import React, { useState } from 'react';
import { Container, Wrapper, Wrap, ToolsWrap, QuestionWrap, ButtonsWrap, DButtonsWrap, FButtonsWrap, Buttons, ButtonsName, ButtonWrap } from './AdsCalElements';
import { FilterInputWrap, RangeInput, NumberInput, NumberInputWrap, UpcodeWrap } from '../SetUpPtc/SetUpPtcFilterElements.js';
import { HelpIcon, HelpWrap } from '../HelpElements';
import { MsgText, MsgWrap } from '../MessageElements';
import { TinyFormButton } from '../ButtonElements';

const AdsCal = ({ toolsData, durationData, durationButtons, bannerData, bannerButtons, filtersData, numberVisitsData, numberImpData, resultData, bottomButton }) => {
    const [adsName, setAdsName] = useState();
    const [adsNameIndex, setAdsNameIndex] = useState();
    const [duration, setDuration] = useState(0);
    const [durationIndex, setDurationIndex] = useState(0);
    const [bannerSize, setBannerSize] = useState(0);
    const [bannerSizeIndex, setBannerSizeIndex] = useState(2);
    const [filters, setFilters] = useState([]);
    const [filtersIndex, setFiltersIndex] = useState([]);
    const [visitValue, setVisitValue] = useState(1000);
    const [impValue, setImpValue] = useState(10000);
    const [showHelp, setShowHelp] = useState(false);
    const [page, setPage] = useState(1);
    const [error, setError] = useState('');
    const costFilters = filters.length > 0 ? filters.reduce(((x, y) => parseFloat(x) + parseFloat(y))) : 0;
    const CPC = (adsName * (1 + parseFloat(duration) + parseFloat(bannerSize) + parseFloat(costFilters))).toFixed(2);
    const totalCost = (adsName === 3 || adsName === 1 || adsName === 0.5) ? Math.floor(impValue * CPC) : Math.floor(visitValue * CPC);
    const upcodeValue = (adsName === 3 || adsName === 1 || adsName === 0.5) ? Math.floor(impValue * 0.02) : Math.floor(visitValue * 0.02);

    const handleAdsNameClick = (e, index) => {
        const adsNameTarget = e.target.getAttribute('name');
        if (adsNameTarget && adsNameTarget !== adsName) {
            setAdsName(parseFloat(adsNameTarget));
            setAdsNameIndex(index);
        };
        setError('');
    };

    const handleDurationClick = (e, index) => {
        const durationTarget = e.target.getAttribute('name');
        if (durationTarget && durationTarget !== duration) {
            setDuration(parseFloat(durationTarget));
            setDurationIndex(index);
        };
    };
    const handleBannerSizeClick = (e, index) => {
        const bannerSizeTarget = e.target.getAttribute('name');
        if (bannerSizeTarget && bannerSizeTarget !== bannerSize) {
            setBannerSize(parseFloat(bannerSizeTarget));
            setBannerSizeIndex(index);
        };
    };

    const handleFiltersClick = (e, index) => {
        const filtersTarget = e.target.getAttribute('name');
        if (filtersTarget && !filters.includes(filtersTarget) && filters.length <= 2) {
            setFilters(filters => [...filters, filtersTarget]);
            setFiltersIndex(filtersIndex => [...filtersIndex, index]);
        } else if (filtersTarget && filters.includes(filtersTarget)) {
            setFilters(filters.filter(removeFilter => removeFilter !== filtersTarget));
            setFiltersIndex(filtersIndex.filter(removeDeviceIndex => removeDeviceIndex !== index));
        };
    };

    const handleChange = (e) => {
        let number = parseInt(e.target.value)
        if (!isNaN(number)) {
            setVisitValue(parseInt(number));
        };
        setError('');

    };

    const handleImpChange = (e) => {
        let number = parseInt(e.target.value)
        if (!isNaN(number)) {
            setImpValue(parseInt(number));
        };
        setError('');

    };

    const handleButtonClick = () => {
        if (page === 1 && adsName === undefined) {
            setError('You should select the advertising method First.')
        };
        if (page === 1 && adsName !== undefined && adsName !== 4) {
            setPage(page + 1);
            setError('');
        };

        if (page === 1 && adsName !== undefined && adsName === 4) {
            setPage(page + 2);
            setError('');
        };
        if (page === 2 && (duration !== undefined || bannerSize !== undefined)) {
            setPage(page + 1);
        };
        if (page === 3 && (adsName === 10 || adsName === 8 || adsName === 6)) {
            (visitValue < 1000 || visitValue > 100000) && setError('This number is not in the range.');
            (visitValue >= 1000 && visitValue <= 100000) && setPage(page + 1);
        };

        if (page === 3 && adsName === 4) {
            (visitValue < 1000 || visitValue > 1000000) && setError('This number is not in the range.');
            (visitValue >= 1000 && visitValue <= 1000000) && setPage(page + 1);
        };

        if (page === 3 && (adsName === 3 || adsName === 1 || adsName === 0.5)) {
            (impValue < 10000 || impValue > 1000000) && setError('This number is not in the range.');
            (impValue >= 10000 && impValue <= 1000000) && setPage(page + 1);
        };

        if (page === 4) {
            if (page === 4) {
                setAdsName();
                setAdsNameIndex();
                setDuration(0);
                setDurationIndex(0);
                setBannerSize(0);
                setBannerSizeIndex(2);
                setFilters([]);
                setFiltersIndex([]);
                setVisitValue(1000);
                setImpValue(10000);
                setError('');
                setPage(1);
            };
        };

    };

    return (
        <Container id='ads calculator'>
            <Wrapper>
                <Wrap>
                    {page === 1 &&
                        <ToolsWrap>
                            <QuestionWrap>
                                <p>{toolsData.question}</p>
                            </QuestionWrap>
                            <ButtonsWrap>
                                {toolsData.buttons.map((button, index) => (
                                    <Buttons key={index} >
                                        <ButtonsName name={button.name} onClick={(e) => handleAdsNameClick(e, index)} itemActive={adsNameIndex === index ? 1 : 0}>
                                            {button.value}
                                        </ButtonsName>
                                    </Buttons>
                                ))}
                            </ButtonsWrap>
                        </ToolsWrap>
                    }
                    {page === 2 && adsName !== undefined && (adsName === 10 || adsName === 8 || adsName === 6) &&
                        <ToolsWrap>
                            <QuestionWrap>
                                <p>{durationData.question}</p>
                            </QuestionWrap>
                            <DButtonsWrap>
                                {durationButtons.map((button, index) => (
                                    <Buttons key={index}>
                                        <ButtonsName name={button.name} onClick={(e) => handleDurationClick(e, index)} itemActive={durationIndex === index ? 1 : 0}>
                                            {adsName === 6 ? 2 * button.value : button.value} sec <br />
                                            {button.name === '0.5' ? '+50% CPI' : button.name === '1.0' ? '+100% CPI' : button.name === '1.5' ? '+150% CPI' : null}
                                        </ButtonsName>
                                    </Buttons>
                                ))}
                            </DButtonsWrap>
                        </ToolsWrap>
                    }
                    {page === 2 && adsName === 1 &&
                        <ToolsWrap>
                            <QuestionWrap>
                                <p>{bannerData.question}</p>
                            </QuestionWrap>
                            <ButtonsWrap>
                                {bannerButtons.map((button, index) => (
                                    <Buttons key={index}>
                                        <ButtonsName name={button.name} onClick={(e) => handleBannerSizeClick(e, index)} itemActive={bannerSizeIndex === index ? 1 : 0}>
                                            {button.value} <br />
                                            {button.name === '0.5' ? '+50% CPI' : button.name === '1.0' ? '+100% CPI' : null}
                                        </ButtonsName>
                                    </Buttons>
                                ))}
                            </ButtonsWrap>
                        </ToolsWrap>
                    }
                    {page === 2 &&
                        <ToolsWrap>
                            <QuestionWrap>
                                <p>{filtersData.question}</p>
                            </QuestionWrap>
                            <FButtonsWrap>
                                {filtersData.buttons.map((button, index) => (
                                    <Buttons key={index}>
                                        <ButtonsName name={button.name} onClick={(e) => handleFiltersClick(e, index)} itemActive={filtersIndex.includes(index) ? 1 : 0}>
                                            {button.value} <br />
                                            {button.name === '0.1' ? '+10% CPC' : '+25% CPC'}
                                        </ButtonsName>
                                    </Buttons>
                                ))}
                            </FButtonsWrap>
                        </ToolsWrap>
                    }
                    {page === 3 && (adsName === 10 || adsName === 8 || adsName === 6 || adsName === 4) &&
                        <ToolsWrap>
                            <QuestionWrap>
                                <p>{numberVisitsData.question}</p>
                            </QuestionWrap>
                            <FilterInputWrap style={{ background: 'rgba(0, 0, 0, 0.2)' }}>
                                <RangeInput
                                    type='range'
                                    min='1000'
                                    max={adsName === 2 ? '1000000' : '100000'}
                                    value={visitValue}
                                    onChange={handleChange}
                                />
                                <NumberInputWrap style={{ gridTemplateColumns: '1fr' }}>
                                    <NumberInput
                                        type='number'
                                        value={visitValue}
                                        onChange={handleChange}
                                    />
                                </NumberInputWrap>
                                {totalCost >= 250000 && <UpcodeWrap>
                                    <span>
                                        with upcode: {upcodeValue} visits
                                    </span>
                                    <HelpIcon onClick={() => setShowHelp(!showHelp)} show={showHelp ? 1 : 0} />
                                    <HelpWrap show={showHelp ? 1 : 0}>
                                        <p>If cost of your ads is more than 25$, based on your level, you get more credits. Masternode members get 10% more credit. </p>
                                    </HelpWrap>
                                </UpcodeWrap>}
                            </FilterInputWrap>
                        </ToolsWrap>
                    }
                    {page === 3 && (adsName === 3 || adsName === 1 || adsName === 0.5) &&
                        <ToolsWrap>
                            <QuestionWrap>
                                <p>{numberImpData.question}</p>
                            </QuestionWrap>
                            <FilterInputWrap style={{ background: 'rgba(0, 0, 0, 0.2)' }}>
                                <RangeInput
                                    type='range'
                                    min='10000'
                                    max='1000000'
                                    value={impValue}
                                    onChange={handleImpChange}
                                />
                                <NumberInputWrap style={{ gridTemplateColumns: '1fr' }}>
                                    <NumberInput
                                        type='number'
                                        value={impValue}
                                        onChange={handleImpChange}
                                    />
                                </NumberInputWrap>
                                {totalCost >= 250000 && <UpcodeWrap>
                                    <span>
                                        with upcode: {upcodeValue} visits
                                    </span>
                                    <HelpIcon onClick={() => setShowHelp(!showHelp)} show={showHelp ? 1 : 0} />
                                    <HelpWrap show={showHelp ? 1 : 0}>
                                        <p>If cost of your ads is more than 25$, based on your level, you get more credits. Masternode members get 10% more credit. </p>
                                    </HelpWrap>
                                </UpcodeWrap>}
                            </FilterInputWrap>
                        </ToolsWrap>
                    }
                    {page === 4 &&
                        <ToolsWrap>
                            <QuestionWrap>
                                <p>{resultData.desc1} (CPC) = {CPC} adscoin or ${(CPC * 0.0001).toFixed(6)}</p>
                            </QuestionWrap>
                            <QuestionWrap>
                                <p>{resultData.desc2} = {totalCost} adscoin or ${(totalCost * 0.0001).toFixed(4)}</p>
                            </QuestionWrap>
                            {totalCost > 250000 &&
                                <QuestionWrap>
                                    <p>{resultData.desc3} = {upcodeValue * CPC} adscoin or ${(upcodeValue * CPC * 0.0001).toFixed(4)}</p>
                                </QuestionWrap>
                            }
                        </ToolsWrap>
                    }
                </Wrap>
                {error.length > 0 &&
                    <MsgWrap primary={0}>
                        <MsgText primary={0}>{error}</MsgText>
                    </MsgWrap>
                }
                <ButtonWrap>
                    <TinyFormButton type='button' primary={1} big={0} onClick={handleButtonClick}>
                        {page === 1 ? bottomButton.start : page === 2 ? bottomButton.next : page === 3 ? bottomButton.calculate : bottomButton.restart}
                    </TinyFormButton>
                </ButtonWrap>
            </Wrapper>
        </Container>
    )
}
export default AdsCal;
