import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const TypeWrap = styled.div`
    width: 90%;
    max-width: 94vw;
    display: grid;
    grid-template-columns : 1fr 1fr 1fr;
    padding: 10px 0;
    margin-bottom: 15px;
    @media screen and ${device.md} {
	grid-template-columns : 1fr 1fr;
	grid-row-gap: 20px;
    }
    @media screen and ${device.sm} {
	grid-template-columns : 1fr;
	grid-row-gap: 20px;
    }
`
export const TypeCard = styled.div`
    background: rgba(29, 107, 187, 0.5);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 2px 10px rgba(29, 107, 187, 0.7);
    border-radius: 5px;
    width: 180px;
    height: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    margin: auto;
`
export const ImageWrap = styled.div`
    width: 70px;
    height: 70px;
    display: felx;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: rgba(29, 107, 187, 0.75);
    border: 1px solid rgba(29, 107, 187, 0.5);
    box-shadow: 0 0 10px rgba(29, 107, 187, 0.7);
    border-radius: 5px;
    color: #fff;
    font-size: 1rem;
`
export const Image = styled.img`
    width: 95%;
    height: 95%;
    position: absolute;
    border-radius: 5px;
`
export const WContext = styled.div`
    width: 90%;
    background: rgba(29, 107, 187, 0.75);
    border: 1px solid rgba(29, 107, 187, 0.5);
    box-shadow: 0 2px 5px rgba(29, 107, 187, 0.7);
    border-radius: 2px;
    color: #fff;
`
export const WContextItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1.5px solid rgba(39, 117, 85, 0.5);
    padding: 5px;
    span {
	font-size: clamp(0.6rem, 1vw, 0.7rem);
	letter-spacing: 0.5px;
	word-spacing: 1.5px;
	text-transform: uppercase;
	text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    }
`
export const CryptoWalletContainer = styled.div`
    width: 95%;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns : 1fr 1fr 1fr 1fr;
    grid-row-gap: 4px;
`
export const CryptoContainer = styled.div`
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const CryptoWrap = styled.div`
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(29, 107, 187, 0.75);
    border: 1px solid rgba(29, 107, 187, 0.5);
    box-shadow: 0 0 10px rgba(29, 107, 187, 0.7);
    border-radius: 50%;
`
export const Crypto = styled.img`
    width: 28px;
    height: 28px;
`
