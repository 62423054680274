import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { HistoryBody, CountTotalContainer, CountTotalWrap, TitleWrap, DescWrap, ImgCrypto } from './DepositTabElements';
import { PromotionContainer } from '../Promotions/ReferralLinkTabElements';
import { Table, HeadWrap, HeadRow, Head, BodyWrap, BodyRow, Cell } from '../Payments/PaymentsElements';
import { headDepositTable, smallHeadDepositTable } from '../../data/historyData';
import { MsgWrap, MsgText } from '../MessageElements';
import moment from 'moment';
import { Circle } from '../Loading';

const DepositTab = ({ smallView }) => {
    const [info, setInfo] = useState();
    const [apiError, setApiError] = useState('');
    const [loading, setLoading] = useState(false);
    const fetchDepositHistory = async () => {
        setLoading(true);
        try {
            const { data } = await axiosInstance.get('financial/cash-in/history/');
            setInfo(data);
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
        setLoading(false);
    };
    useEffect(() => {
        fetchDepositHistory();
    }, []);
    return (
        <PromotionContainer>
            <HistoryBody>
                {loading &&
                    <div style={{ marginTop: '50px' }}>
                        <Circle style={{ margin: 'auto', width: '50px', height: '50px' }} />
                    </div>
                }
                {!loading && info !== undefined && apiError.length === 0 &&
                    <>
                        <CountTotalContainer>
                            <CountTotalWrap>
                                <TitleWrap>
                                    <h2>count</h2>
                                </TitleWrap>
                                <DescWrap>
                                    <h2>{info.count}</h2>
                                </DescWrap>
                            </CountTotalWrap>
                            <CountTotalWrap>
                                <TitleWrap>
                                    <h2>total</h2>
                                </TitleWrap>
                                <DescWrap>
                                    <h2>{info.total} USDT</h2>
                                </DescWrap>
                            </CountTotalWrap>
                        </CountTotalContainer>
                        <Table style={{ width: '100%' }}>
                            <HeadWrap>
                                <HeadRow>
                                    {!smallView && headDepositTable.map((item, index) => (
                                        <Head key={index}>
                                            {item}
                                        </Head>
                                    ))}
                                    {smallView && smallHeadDepositTable.map((item, index) => (
                                        <Head key={index}>
                                            {item}
                                        </Head>
                                    ))}
                                </HeadRow>
                            </HeadWrap>
                            <BodyWrap>
                                {info.info.map((row, index) => (
                                    <BodyRow key={index}>
                                        <Cell>
                                            <ImgCrypto
                                                src={row.processor === 'TRX'
						    ? 'https://icefaucet.com/media/financial/crypto_image/tron.png'
						    : row.processor === 'USDT'
							? 'https://icefaucet.com/media/financial/crypto_image/tether.png'
							: row.processor === 'DOGE'
							    ? 'https://icefaucet.com/media/financial/crypto_image/dogecoin.png'
							    : row.processor === 'BTC'
							        ? 'https://icefaucet.com/media/financial/crypto_image/bitcoin.png'
							        : row.processor === 'TON'
							            ? 'https://icefaucet.com/media/financial/crypto_image/toncoin.png'
							            : row.processor === 'SOL'
							                ? 'https://icefaucet.com/media/financial/crypto_image/solana.png'
									: row.processor === 'ICECOIN'
							                    ? 'https://icefaucet.com/media/financial/crypto_image/icecoin.png'
							                    : row.processor === 'PCOIN'
							                        ? 'https://icefaucet.com/media/financial/crypto_image/pcoin.png'
							                        : 'https://icefaucet.com/media/financial/crypto_image/dollar.png'}
                                                alt={row.processor}
                                            />
                                        </Cell>
                                        <Cell>
                                            {row.value_processor}
                                        </Cell>
                                        {!smallView &&
                                            <>
                                                <Cell>
                                                    {row.coin === 'A' ? 'adscoin' : 'pcoin'}
                                                </Cell>
                                                <Cell>
                                                    {row.value_coin}
                                                </Cell>
                                            </>
                                        }
                                        <Cell>
                                            {row.status === 'C' ? 'confirmed' : row.status === 'F' ? 'failed' : row.status === 'R' ? 'refunded' : row.status === 'E' ? 'expired' : 'pending'}
                                        </Cell>
                                        <Cell style={{ fontSize: '0.8rem' }}>
                                            {moment(row.created).fromNow()}
                                        </Cell>
                                    </BodyRow>
                                ))}
                            </BodyWrap>
                        </Table>
                    </>
                }
                {!loading && apiError.length > 0 &&
                    <MsgWrap style={{ width: '80%', margin: '0 auto' }} primary={0}>
                        <MsgText primary={0}>
                            {apiError}
                        </MsgText>
                    </MsgWrap>
                }
            </HistoryBody>
        </PromotionContainer >
    )
}
export default DepositTab;
