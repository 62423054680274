export const withdrawalInfo = [
    {
	src: 'https://icefaucet.com/media/help/blog/manual_wallet.png',
	alt: 'wallet image',
	title: 'your cryptocurrency wallet',
	min: '$1',
	max: '$200',
	limitation: 'once daily',
	type: 'W',
	fee: 'different'
    },
    {
	src: 'https://icefaucet.com/media/help/blog/pay.PNG',
	alt: 'Payeer logo',
	title: 'your payeer wallet',
	min: '$1',
	max: '$100',
	limitation: 'once daily',
	type: 'P',
	fee: '0.5%'
    },
    {
	src: 'https://icefaucet.com/media/help/blog/faucetpay.jpg',
        alt: 'faucetpay logo',
        title: 'your faucetpay wallet',
        min: '$0.25',
        max: '$5',
        limitation: 'once daily',
        type: 'F',
	fee: '0'
    }
];
