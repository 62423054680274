import styled from 'styled-components';
import NumberFormat from 'react-number-format';

export const ContentWrap = styled.div`
    display: flex;
    flex-direction: column;
`
export const ResetCodeWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto 10px auto;
`
export const NumberFormatCode = styled(NumberFormat)`
    color: #277555;
    font-size: 1.25rem;
    font-weight: bold;
    width: 100%;
    max-width: 200px;
    text-align: center;

    &:focus {
        outline: 3px solid #277555;
    }
`
export const TimerResetCode = styled.span`
    color: #01DBBB;
    font-size: 1.1rem;
    text-align: center;
`
