import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const PlayContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
`
export const Bar = styled.div`
    width: ${props => props.width};
    height: 5px;
    background: ${({ stop }) => (stop ? 'red' : '#277555')};
`
export const Iframe = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
    overflow-x: hidden;
`
export const MessageContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #010606 0% , #1D6BBB 30%, #277555 70%, #010606 105%);
`
export const MsgItem = styled.div`
    width: 80%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 30px;
    align-items: center;
    @media screen and ${device.md} {
        grid-template-columns: 1fr;
        grid-row-gap: 5px;
        padding: 5px;
	width: 100%;
    }
`
