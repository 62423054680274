import React from 'react';
import { Table, HeadWrap, HeadRow, BodyWrap, BodyRow } from '../UpgradeContainer/UpgradeContainerElements';
import { Container, PIContainer, PIWrap, PIIntroCard, IntroImgWrap, IntroTitle, PIItems, PIItemsWrap, PIItemsCard, TitleWrap, ItemsContentWrap, Wrapper, HeaderAds, CellAds } from './AdsToolsElements';

const AdsTools = ({ adsToolsIntro, adsToolsHead, adsToolsRow }) => {
    return (
        <Container id='ads tools'>
            <PIContainer>
                {adsToolsIntro.map((item, index) => (
                    <PIWrap key={index}>
                        <PIIntroCard>
                            <IntroImgWrap>
                                <img src={item.mainImg} alt={item.mainAlt} />
                            </IntroImgWrap>
                            <IntroTitle>
                                <h4>{item.mainTitle}</h4>
                            </IntroTitle>
                        </PIIntroCard>
                        <PIItems>
                            {item.tools.map((detail, index) => (
                                <PIItemsWrap key={index}>
                                    <PIItemsCard>
                                        <TitleWrap>
                                            <h4>{detail.title}</h4>
                                        </TitleWrap>
                                        <ItemsContentWrap>
                                            <div>{detail.icon}</div>
                                            <p>{detail.desc1}</p>
                                        </ItemsContentWrap>
                                        <ItemsContentWrap>
                                            <div>{detail.icon}</div>
                                            <p>{detail.desc2}</p>
                                        </ItemsContentWrap>
                                        {detail.desc3.length !== 0 &&
                                            <ItemsContentWrap>
                                                <div>{detail.icon}</div>
                                                <p>{detail.desc3}</p>
                                            </ItemsContentWrap>
                                        }
                                        {detail.desc4.length !== 0 &&
                                            <ItemsContentWrap>
                                                <div>{detail.icon}</div>
                                                <p>{detail.desc4}</p>
                                            </ItemsContentWrap>
                                        }
                                    </PIItemsCard>
                                </PIItemsWrap>
                            ))}
                        </PIItems>
                    </PIWrap>
                ))}
            </PIContainer>
            <Wrapper>
                <Table>
                    <HeadWrap>
                        <HeadRow>
                            {adsToolsHead.map((header, index) => (
                                <HeaderAds key={index}>
                                    {header}
                                </HeaderAds>
                            ))}
                        </HeadRow>
                    </HeadWrap>
                    <BodyWrap>
                        {adsToolsRow.map((row, index) => (
                            <BodyRow key={index}>
                                <CellAds>
                                    {row.details}
                                </CellAds>
                                <CellAds>
                                    {row['sponsor link']}
                                </CellAds>
                                <CellAds>
                                    {row.banner}
                                </CellAds>
                                <CellAds>
				    {row['ice story']}
                                </CellAds>
				<CellAds>
				    {row.auto}
				</CellAds>
                                <CellAds>
                                    {row['video']}
                                </CellAds>
                                <CellAds>
                                    {row.surf}
                                </CellAds>
                                <CellAds>
                                    {row.ptc}
                                </CellAds>
                            </BodyRow>
                        ))}
                    </BodyWrap>
                </Table>
            </Wrapper>
        </Container>
    )
}

export default AdsTools;
