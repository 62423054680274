import React, { useEffect, useState } from 'react';
import axiosInstance from '../axios';
import IceCard from '../components/CardMining/IceCard';
import NewSpecialCard from '../components/CardMining/NewSpecialCard';
import Card from '../components/CardMining/Card';
import { IceCardContainer, IceTimer } from '../components/CardMining/IceCardElements';
import { CardContainer } from '../components/CardMining/CardElements';
import { TabHeaderContainer, TabHeaderWrap } from '../components/LuckyWheel/LuckyWheelElements';
import { TabCradMiningData } from '../data/cardMiningData';
import { StackContainer, StackWrap, StackDiscWrap, TitleWrap } from '../components/Stack/StackElements';
import Countdown, { zeroPad } from 'react-countdown';
import { TinyFormButton } from '../components/ButtonElements';
import { MsgWrap, MsgText } from '../components/MessageElements';
import { Circle } from '../components/Loading';

const CardMiningPage = () => {
    const [info, setInfo] = useState();
    const [newSpecialCard, setNewSpecialCard] = useState();
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [apiError, setApiError] = useState('');
    const [tabName, setTabName] = useState('B');
    const [loadingGame, setLoadingGame] = useState(false);
    const [fetchClaim, setFetchClaim] = useState(false);
    const [claimDone, setClaimDone] = useState(false);
    const [iceApiError, setIceApiError] = useState('');
    const [isIcecard, setIsIcecard] = useState(0);
    useEffect(() => {
	const fetchCradInfo = async () => {
	    try {
		const { data } = await axiosInstance.post('mining/card/info/', {
		    'type': tabName
		});
		setInfo(data);
		Object.keys(data.new_special).length > 0 && setNewSpecialCard(data.new_special);
	    } catch (error) {
		error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	    };
	    setLoading(false);
	    setLoading2(false);
	};
	fetchCradInfo();
    }, [tabName, isIcecard]);
    const handleTabClick = (tab) => {
	if (tab !== tabName) {
	    setTabName(tab);
	    setLoading2(true);
	};
    };
    const renderer = ({ hours, minutes, seconds }) => {
        return <IceTimer red={info.game_timer < 60 * 60 ? 1 : 0}>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</IceTimer>;
    };
    const handleClaim = () => {
	setFetchClaim(true);
	setLoadingGame(true);
	fetchClaimIcecard();
    };
    const fetchClaimIcecard = async () => {
	try {
	    await axiosInstance.get('mining/icecard/claim/');
	    setClaimDone(true);
	} catch (error) {
	    error.response && error.response.data.detail ? setIceApiError(error.response.data.detail) : setIceApiError(error.message);
	};
	setLoadingGame(false);
    };
    return (
	<>
	    {loading &&
		<div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
		    <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		</div>
	    }
	    {!loading && apiError &&
		<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
		    <MsgText primary={0}>
			{apiError}
		    </MsgText>
		</MsgWrap>
	    }
	    {!loading && !apiError && info !== undefined &&
		<StackContainer primary={1} style={{ top: '0' }}>
		    <StackWrap primary={1}>
			<TitleWrap primary={1}>
			    <h2>guidance</h2>
			</TitleWrap>
			<StackDiscWrap>
			    <p>
			        Take a card to mine icecoins. <br />
			        Level up the card to mine more icecoins. <br />
			        Find all three ice cards and win 5 spins. The game is updated every day at 00:00 server time.
			    </p>
			</StackDiscWrap>
		    </StackWrap>
		</StackContainer>
	    }
	    {!loading && !apiError && info !== undefined && info.game_timer > 0 &&
		<StackContainer primary={0} style={{ top: '0' }}>
		    <StackWrap primary={0} style={{ padding: '0' }}>
			{info !== undefined &&
			    <Countdown date={Date.now() + info.game_timer * 1000} renderer={renderer} />
			}
		        <IceCardContainer>
			    {info.icecard_data.map((item, index) => (
				<IceCard key={index} card={item} />
			    ))}
		        </IceCardContainer>
			{iceApiError &&
			    <MsgWrap primary={0} style={{ width: '70%', margin: '0 auto 15px auto' }}>
				<MsgText primary={0}>
				    {iceApiError}
				</MsgText>
			    </MsgWrap>
			}
		        {!iceApiError && !claimDone && info !== undefined && info.number_icecard === 1 &&
			    <MsgWrap primary={1} style={{ width: '70%', margin: '0 auto 15px auto' }}>
				<MsgText primary={1}>
				    You guessed one icecard correctly.
				</MsgText>
			    </MsgWrap>
			}
		        {!iceApiError && !claimDone && info !== undefined && info.number_icecard === 2 &&
			    <MsgWrap primary={1} style={{ width: '70%', margin: '0 auto 15px auto' }}>
				<MsgText primary={1}>
				    You guessed two icecards correctly. Only one more icecard left.
				</MsgText>
			    </MsgWrap>
			}
		        {!iceApiError && !claimDone && info !== undefined && info.number_icecard === 3 && !info.win_icegame &&
			    <TinyFormButton primary={0} big={0} style={{ margin: '0 auto 15px auto' }} onClick={!fetchClaim ? handleClaim : null}>
				{loadingGame ? <Circle style={{ margin: 'auto', width: '15px', height: '15px' }} /> : 'Get Your Reward'}
			    </TinyFormButton>
			}
		        {!iceApiError && (claimDone || info.win_icegame) &&
			    <MsgWrap primary={1} style={{ width: '70%', margin: '0 auto 15px auto' }}>
				<MsgText primary={1}>
				    You win today icecard game and get 5 spins, congratulatoin!
				</MsgText>
			    </MsgWrap>
			}
		    </StackWrap>
		</StackContainer>
	    }
	    {!loading && !apiError && info !== undefined &&
		<StackContainer primary={0} style={{ top: '0' }}>
		    <StackWrap primary={0} style={{ padding: '0' }}>
			<TabHeaderContainer style={{ borderRadius: '10px 10px 0 0' }}>
			    {TabCradMiningData.map((item, index) => (
				<TabHeaderWrap key={index} active={item.name === tabName ? 1 : 0} onClick={() => handleTabClick(item.name, index)} style={{ borderRadius: '10px 10px 0 0' }}>
				    <h2>{item.value}</h2>
				</TabHeaderWrap>
			    ))}
		        </TabHeaderContainer>
			{loading2 &&
			    <div style={{ width: '100%', height: '60vh', display: 'flex', alignItems: 'center' }}>
				<Circle style={{ margin: 'auto', width: '60px', height: '60px' }} />
			    </div>
			}
		        {newSpecialCard && !loading2 &&
			    <NewSpecialCard newSpecialCard={newSpecialCard} />
			}
			{info !== undefined && info.info.length === 0 && !loading2 &&
			    <MsgWrap primary={0} style={{ width: '80%', margin: '25px auto' }}>
				<MsgText primary={0}>
				    Currently, there are not any cards here, try other tabs.
				</MsgText>
			    </MsgWrap>
			}
			{info !== undefined && info.info.length > 0 && !loading2 &&
			    <CardContainer>
				{info.info.map((item, index) => (
				    <Card key={index} index={index} card={item} setIsIcecard={setIsIcecard} />
				))}
			    </CardContainer>
			}
		    </StackWrap>
		</StackContainer>
	    }
	</>
    )
}
export default CardMiningPage;
