import React from 'react';
import { ActContainer, ActWrap } from '../ActivityContainer/ClickContainerElements';
import {
    ResponsiveContainer,
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Label
} from 'recharts';

const PaymentDollarChart = ({ info }) => {
    return (
        <ActContainer primary={0}>
            <ActWrap primary={0}>
                <ResponsiveContainer width="95%" height={250}>
                    <AreaChart
                        data={info}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0
                        }}
                    >
                        <defs>
                            <linearGradient id="color_withdraw" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="1%" stopColor="rgb(29, 107, 187)" stopOpacity={0.8} />
                                <stop offset="99%" stopColor="rgb(29, 107, 187)" stopOpacity={0} />
                            </linearGradient>
                            <linearGradient id="color_deposit" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="1%" stopColor="rgb(39, 117, 85)" stopOpacity={0.8} />
                                <stop offset="99%" stopColor="rgb(39, 117, 85)" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <XAxis dataKey='date' padding={{ right: 50 }} interval="preserveStartEnd" tick={{ fill: 'rgb(1, 219, 187)' }} tickLine={{ stroke: 'rgb(1, 219, 187)' }} />
                        <YAxis padding={{ top: 10 }} interval={1} tick={{ fill: 'rgb(1, 219, 187)' }} tickLine={{ stroke: 'rgb(1, 219, 187)' }}>
                            <Label angle={-90} position='insideLeft' offset={5} fill='rgb(1, 219, 187)'>
                                Dollar
                            </Label>
                        </YAxis>
                        <Tooltip />
                        <Legend verticalAlign="top" iconSize={20} height={50} />
                        < Area
                            type="monotone"
                            dataKey='withdraw'
                            stroke="rgba(29, 107, 187, 0.8)"
                            fill="url(#color_withdraw)"
                        />
                        <Area
                            type="monotone"
                            dataKey='deposit'
                            stroke="rgba(39, 117, 85, 0.8)"
                            fill="url(#color_deposit)"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </ActWrap>
        </ActContainer>
    )
}

export default PaymentDollarChart;
