import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { StackContainer, StackWrap, TitleWrap } from '../Stack/StackElements';
import Cards from './Cards';
import { withdrawalInfo } from '../../data/withdrawData';

const Gateways = ({ gateData }) => {
    const [cryptoData, setCryptoData] = useState();
    useEffect(() => {
	const fetchCryptoInfo = async () => {
	    const { data } = await axios.get('https://icefaucet.com/api/financial/crypto/data/');
	    setCryptoData(data);
	};
	fetchCryptoInfo();
    }, []);
    return (
	<StackContainer primary={0}>
	    <StackWrap primary={0}>
		<TitleWrap primary={0}>
		    <h2>{gateData.desc}</h2>
		</TitleWrap>
	    {cryptoData !== undefined &&
		<Cards info={withdrawalInfo} crypto={cryptoData}/>
	    }
	    </StackWrap>
	</StackContainer>
    )
}
export default Gateways;
