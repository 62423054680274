import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const NSCWarp = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const NSCImgContainer = styled.div` 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    @media screen and ${device.sm} {
	padding: 5px;
    }
`
export const NSCImgBackground = styled.div`
    width: 100%;
    max-width: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    img {
	width: 100%;
	filter: blur(8px);
	-webkit-filter: blur(8px);
	-o-object-fit: cover;
	object-fit: cover;
	background: #000;
	border-radius: 5px;
	opacity: 0.5;
    }
`
export const NSCImgWrap = styled.div`
    position: absolute;
    width: 90%;
    max-width: 510px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    @media screen and ${device.md} {
	width: 77%;
    }
    img {
	width: 100%;
	border: 4px solid rgba(29, 107, 187, 0.4);
	border-radius: 10px;
	@media screen and ${device.sm} {
	    border: 0 solid rgba(29, 107, 187, 0.4);
	}
    }
`
export const NSCHeaderWrap = styled.div`
    position: absolute;
    z-index: 2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const SectionName = styled.div`
    width: 100px;
    background: rgba(29, 107, 187, 0.8);
    text-align: center;
    rotate: -25deg;
    border-radius: 5px;
    padding: 3px;
    margin-top: 12px;
    margin-left: -12px;
    @media screen and ${device.sm} {
	width: 85px;
	margin-top: 8px;
	margin-left: -8px;
    }
    h3 {
	font-size: clamp(0.65rem, 2.7vw, 0.8rem);
	font-weight: 400;
	color: #fff;
	letter-spacing: 0.5px;
	word-spacing: 1px;
	text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    }
`
export const NSCTitle = styled.div`
    background: rgba(0, 0, 0, 0.6);
    padding: 4px 2px;
    margin: 5px 4px 0 0;
    h2 {
	color: rgba(1, 219, 187, 1);
	font-size: clamp(0.7rem, 2.7vw, 1rem);
	letter-spacing: 1.1px;
	word-spacing: 1.4px;
	text-transform: uppercase;
	text-align: center;
    }
`
export const NSCEndTimer = styled.div`
    position: absolute;
    z-index: 2;
    bottom: 8px;
    left: 8px;
    width: 150px;
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0.8;
`
export const Timer = styled.h4`
    color: rgba(1, 219, 187, 1);
    font-size: clamp(0.65rem, 2.7vw, 0.8rem);
    letter-spacing: 1.3px;
`
