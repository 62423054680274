export const toolsData = {
    question: 'Which services do you want to use?',
    buttons: [
        {
            value: 'PTC',
            name: '10.0'
        },
        {
            value: 'surfing',
            name: '8.0'
        },
        {
            value: 'video',
            name: '6.0'
        },
        {
	    value: 'auto',
	    name: '4.0'
	},
	{
	    value: 'ice story',
	    name: '3.0'
	},
        {
            value: 'banner',
            name: '1.0'
        },
        {
            value: 'sponsor link',
            name: '0.5'
        }
    ]
};
export const langToolsData = [
    {
        lang: 'es',
        info: {
            question: '¿Qué servicios desea utilizar?',
            buttons: [
                {
                    value: 'PTC',
                    name: '10.0'
                },
                {
                    value: 'surf',
                    name: '8.0'
                },
                {
                    value: 'video',
                    name: '6.0'
                },
		{
		    value: 'auto',
		    name: '4.0'
		},
		{
		    value: 'historia de hielo',
		    name: '3.0'
		},
                {
                    value: 'bandera',
                    name: '1.0'
                },
                {
                    value: 'enlace de patrocinador',
                    name: '0.5'
                }
            ]
        }
    },
    {
        lang: 'ru',
        info: {
            question: 'Какими услугами вы хотите воспользоваться?',
            buttons: [
                {
                    value: 'PTC',
                    name: '10.0'
                },
                {
                    value: 'серфинг',
                    name: '8.0'
                },
                {
                    value: 'видео',
                    name: '6.0'
                },
                {
		    value: 'авто',
		    name: '4.0'
		},
		{
		    value: 'ледяная история',
		    name: '3.0'
		},
                {
                    value: 'баннер',
                    name: '1.0'
                },
                {
                    value: 'спонсорская ссылка',
                    name: '0.5'
                }
            ]
        }
    },
    {
        lang: 'pt',
        info: {
            question: 'Quais serviços você deseja usar?',
            buttons: [
                {
                    value: 'PTC',
                    name: '10.0'
                },
                {
                    value: 'surf',
                    name: '8.0'
                },
                {
                    value: 'vídeo',
                    name: '6.0'
                },
                {
		    value: 'auto',
		    name: '4.0'
		},
		{
		    value: 'história de gelo',
		    name: '3.0'
		},
                {
                    value: 'bandeira',
                    name: '1.0'
                },
                {
                    value: 'link do patrocinador',
                    name: '0.5'
                }
            ]
        }
    },
    {
        lang: 'fr',
        info: {
            question: 'Quels services souhaitez-vous utiliser?',
            buttons: [
                {
                    value: 'PTC',
                    name: '10.0'
                },
                {
                    value: 'surfant',
                    name: '8.0'
                },
                {
                    value: 'vidéo',
                    name: '6.0'
                },
                {
		    value: 'auto',
		    name: '4.0'
		},
		{
		    value: 'histoire de glace',
		    name: '3.0'
		},
                {
                    value: 'bannière',
                    name: '1.0'
                },
                {
                    value: 'lien parrain',
                    name: '0.5'
                }
            ]
        }
    },
    {
        lang: 'de',
        info: {
            question: 'Welche Dienste möchten Sie nutzen?',
            buttons: [
                {
                    value: 'PTC',
                    name: '10.0'
                },
                {
                    value: 'Surfen',
                    name: '8.0'
                },
                {
                    value: 'Video',
                    name: '6.0'
                },
                {
		    value: 'Auto',
		    name: '4.0'
		},
		{
		    value: 'Eisgeschichte',
		    name: '3.0'
		},
                {
                    value: 'Banner',
                    name: '1.0'
                },
                {
                    value: 'Sponsor-Link',
                    name: '0.5'
                }
            ]
        }
    },
    {
        lang: 'it',
        info: {
            question: 'Quali servizi vuoi utilizzare?',
            buttons: [
                {
                    value: 'PTC',
                    name: '10.0'
                },
                {
                    value: 'fare surf',
                    name: '8.0'
                },
                {
                    value: 'video',
                    name: '6.0'
                },
                {
		    value: 'auto',
		    name: '4.0'
		},
		{
		    value: 'storia di ghiaccio',
		    name: '3.0'
		},
                {
                    value: 'bandiera',
                    name: '1.0'
                },
                {
                    value: 'link dello sponsor',
                    name: '0.5'
                }
            ]
        }
    }
];
export const durationData = {
    question: 'Select the duration of watching your ads:',
};
export const langDurationData = [
    {
        lang: 'es',
        info: {
            question: 'Seleccione la duración de visualización de sus anuncios:',
        }
    },
    {
        lang: 'ru',
        info: {
            question: 'Выберите продолжительность просмотра вашей рекламы:',
        }
    },
    {
        lang: 'pt',
        info: {
            question: 'Selecione a duração da exibição de seus anúncios:',
        }
    },
    {
        lang: 'fr',
        info: {
            question: 'Sélectionnez la durée de visionnage de vos annonces:',
        }
    },
    {
        lang: 'de',
        info: {
            question: 'Wählen Sie aus, wie lange Ihre Anzeigen angesehen werden:',
        }
    },
    {
        lang: 'it',
        info: {
            question: 'Seleziona la durata della visione dei tuoi annunci:',
        }
    }
];
export const durationButtons = [
    {
        value: 15,
        name: '0'
    },
    {
        value: 30,
        name: '0.5'
    },
    {
        value: 45,
        name: '1.0'
    },
    {
        value: 60,
        name: '1.5'
    }
]
export const bannerData = {
    question: 'Select the size of your banner:',
};
export const langBannerData = [
    {
        lang: 'es',
        info: {
            question: 'Selecciona el tamaño de tu banner:',
        }
    },
    {
        lang: 'ru',
        info: {
            question: 'Выберите размер вашего баннера:',
        }
    },
    {
        lang: 'pt',
        info: {
            question: 'Selecione o tamanho do seu banner:',
        }
    },
    {
        lang: 'fr',
        info: {
            question: 'Sélectionnez la taille de votre bannière :',
        }
    },
    {
        lang: 'de',
        info: {
            question: 'Wählen Sie die Größe Ihres Banners:',
        }
    },
    {
        lang: 'it',
        info: {
            question: 'Seleziona la dimensione del tuo banner:',
        }
    }
];
export const bannerButtons = [
    {
        value: '320 x 50',
        name: '0'
    },
    {
        value: '320 x 100',
        name: '0'
    },
    {
        value: '300 x 250',
        name: '0'
    },
    {
        value: '728 x 90',
        name: '0'
    },
    {
        value: '160 x 600',
        name: '0.5'
    },
    {
        value: '300 x 600',
        name: '1.0'
    },
];
export const filtersData = {
    question: 'Which filters do you want to use? You could select more than one.',
    buttons: [
        {
            value: 'target language',
            name: '0.1'
        },
        {
            value: 'target countries',
            name: '0.25'
        }
    ]
};
export const langFiltersData = [
    {
        lang: 'es',
        info: {
            question: '¿Qué filtros quieres usar? Podría seleccionar más de uno.',
            buttons: [
                {
                    value: 'lengua de llegada',
                    name: '0.1'
                },
                {
                    value: 'países de destino',
                    name: '0.25'
                }
            ]
        }
    },
    {
        lang: 'ru',
        info: {
            question: 'Какие фильтры вы хотите использовать? Вы можете выбрать более одного.',
            buttons: [
                {
                    value: 'язык перевода',
                    name: '0.1'
                },
                {
                    value: 'целевые страны',
                    name: '0.25'
                }
            ]
        }
    },
    {
        lang: 'pt',
        info: {
            question: 'Quais filtros você deseja usar? Você poderia selecionar mais de um.',
            buttons: [
                {
                    value: 'idioma alvo',
                    name: '0.1'
                },
                {
                    value: 'países-alvo',
                    name: '0.25'
                }
            ]
        }
    },
    {
        lang: 'fr',
        info: {
            question: 'Quels filtres souhaitez-vous utiliser ? Vous pouvez en sélectionner plusieurs.',
            buttons: [
                {
                    value: 'langue cible',
                    name: '0.1'
                },
                {
                    value: 'pays cibles',
                    name: '0.25'
                }
            ]
        }
    },
    {
        lang: 'de',
        info: {
            question: 'Welche Filter möchten Sie verwenden? Sie können mehrere auswählen.',
            buttons: [
                {
                    value: 'Zielsprache',
                    name: '0.1'
                },
                {
                    value: 'Zielländer',
                    name: '0.25'
                }
            ]
        }
    },
    {
        lang: 'it',
        info: {
            question: 'Quali filtri vuoi utilizzare? Potresti selezionarne più di uno.',
            buttons: [
                {
                    value: 'lingua di destinazione',
                    name: '0.1'
                },
                {
                    value: 'paesi bersaglio',
                    name: '0.25'
                }
            ]
        }
    }
];
export const numberVisitsData = {
    question: 'How many visits do you want?'
};

export const langNumberVisitsData = [
    {
        lang: 'es',
        info: {
            question: '¿Cuántas visitas quieres?'
        }
    },
    {
        lang: 'ru',
        info: {
            question: 'Сколько посещений вы хотите?'
        }
    },
    {
        lang: 'pt',
        info: {
            question: 'Quantas visitas você deseja?'
        }
    },
    {
        lang: 'fr',
        info: {
            question: 'Combien de visites souhaitez-vous ?'
        }
    },
    {
        lang: 'de',
        info: {
            question: 'Wie viele Besuche wünschen Sie?'
        }
    },
    {
        lang: 'it',
        info: {
            question: 'Quante visite vuoi?'
        }
    }
];

export const numberImpData = {
    question: 'How many impressions do you want?'
};

export const langNumberImpData = [
    {
        lang: 'es',
        info: {
            question: '¿Cuántas impresiones quieres?'
        }
    },
    {
        lang: 'ru',
        info: {
            question: 'Сколько впечатлений вы хотите?'
        }
    },
    {
        lang: 'pt',
        info: {
            question: 'Quantas impressões você quer?'
        }
    },
    {
        lang: 'fr',
        info: {
            question: "Combien d'impressions voulez-vous?"
        }
    },
    {
        lang: 'de',
        info: {
            question: 'Wie viele Impressionen möchten Sie?'
        }
    },
    {
        lang: 'it',
        info: {
            question: 'Quante impressioni vuoi?'
        }
    }
];
export const resultData = {
    desc1: 'The cost per click',
    desc2: 'The cost of your ads',
    desc3: 'The cost of your Upcode'
};

export const langResultData = [
    {
        lang: 'es',
        info: {
            desc1: 'El costo por clic',
            desc2: 'El costo de sus anuncios',
            desc3: 'El costo de su Upcode'
        }
    },
    {
        lang: 'ru',
        info: {
            desc1: 'Стоимость клика',
            desc2: 'Стоимость вашей рекламы',
            desc3: 'Стоимость вашего Upcode'
        }
    },
    {
        lang: 'pt',
        info: {
            desc1: 'O custo por clique',
            desc2: 'O custo dos seus anúncios',
            desc3: 'O custo do seu Upcode'
        }
    },
    {
        lang: 'fr',
        info: {
            desc1: 'Le coût par clic',
            desc2: 'Le coût de vos annonces',
            desc3: 'Le coût de votre Upcode'
        }
    },
    {
        lang: 'de',
        info: {
            desc1: 'Die Kosten pro Klick',
            desc2: 'Die Kosten Ihrer Anzeigen',
            desc3: 'Die Kosten für Ihren Upcode'
        }
    },
    {
        lang: 'it',
        info: {
            desc1: 'Il costo per clic',
            desc2: 'Il costo dei tuoi annunci',
            desc3: 'Il costo del tuo Upcode'
        }
    }
];
export const bottomButton = {
    start: 'start',
    next: 'next',
    calculate: 'calculate',
    restart: 'restart',
};

export const langBottomButton = [
    {
        lang: 'es',
        info: {
            start: 'comenzar',
            next: 'próxima',
            calculate: 'calcular',
            restart: 'Reanudar',
        }
    },
    {
        lang: 'ru',
        info: {
            start: 'начало',
            next: 'следующий',
            calculate: 'рассчитать',
            restart: 'перезапуск',
        }
    },
    {
        lang: 'pt',
        info: {
            start: 'começar',
            next: 'próxima',
            calculate: 'calcular',
            restart: 'reiniciar',
        }
    },
    {
        lang: 'fr',
        info: {
            start: 'commencer',
            next: 'suivante',
            calculate: 'calculer',
            restart: 'recommencer',
        }
    },
    {
        lang: 'de',
        info: {
            start: 'start',
            next: 'nächste',
            calculate: 'berechnen',
            restart: 'wieder starten',
        }
    },
    {
        lang: 'it',
        info: {
            start: 'inizio',
            next: 'Prossima',
            calculate: 'calculate',
            restart: 'ricomincia',
        }
    }
];
