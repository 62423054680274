import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import { useLocation, useHistory } from 'react-router-dom';
import { SidebarContainer } from '../Sidebar/SidebarElements';
import { CloseIconWrap, CloseIcon } from '../ManageRef/MoreDetailElements';
import { ModalWrapper } from '../AdsControl/MoreInfoModalElements';
import { ContentWrap, TimePriceWrap, TimeWrap, PriceWrap, TimerDeposit, InstructionWrap, ValueAddressWrap, ItemContainer, ItemWrap } from './ModalElements';
import { RefButton } from '../Promotions/ReferralLinkTabElements';
import Countdown, { zeroPad } from 'react-countdown';
import { FormButton } from '../ButtonElements';
import { MsgWrap, MsgText } from '../MessageElements';
import { PtcInputWrap, PtcFormInput, PtcTitleIcon } from '../SetUpPtc/SetUpPtcFormElements.js';

const Modal = ({ crypto, cryptoName, address, value, timer, setTimer, isSubmitting, setIsSubmitting }) => {
    const location = useLocation();
    let history = useHistory();
    const [price, setPrice] = useState(Object.freeze());
    const [getPrice, setGetPrice] = useState(false);
    const [apiError, setApiError] = useState('');
    const [valueTX, setValueTX] = useState(Object.freeze(''));
    const [error, setError] = useState('');
    const [copied, setCopied] = useState(false);
    const [copied2, setCopied2] = useState(false);
    const [complete, setComplete] = useState('');
    const cryptoValue = crypto === 'BTC' ? (value / price / 10000).toFixed(12) : (value / price / 10000).toFixed(6);
    useEffect(() => {
	const fetchEstimatePrice = async () => {
	    if (crypto !== 'USDT') {
		try {
		    const { data } = await axiosInstance.post('financial/estimate/price/', {
			'crypto': cryptoName,
			'abbreviation': crypto,
			'value': value
		    });
		    setPrice(parseFloat(data.price));
		} catch (error) {
		    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
		};
	    } else {
		setPrice(1.000);
	    };
	};
	isSubmitting && fetchEstimatePrice();
    });
    const renderer = ({ minutes, seconds }) => {
        return <TimeWrap red={timer < Date.now() + 5 * 60 * 1000 ? 1 : 0}>
            <TimerDeposit red={timer < Date.now() + 5 * 60 * 1000 ? 1 : 0}>{zeroPad(minutes)}:{zeroPad(seconds)}</TimerDeposit>
        </TimeWrap>;
    };
    const handleChange = (e) => {
        const targetValue = e.target.value;
        setValueTX(targetValue);
        setError('');
    };
    const fetchManualDeposit = async () => {
        try {
            await axiosInstance.post('financial/cash-in/', {
                'price': price,
                'transaction_id': valueTX,
                'coin': location.pathname === '/deposit/pcoin' ? 'P' : 'A',
                'processor': crypto,
                'value_coin': value,
                'address': address,
                'status': 'P'
            });
            setComplete(true);
            setTimeout(() => history.push('/history-panel'), 2000);
        } catch (error) {
            error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
        };
    };
    const handleManualDeposit = () => {
        valueTX.length === 0 && setError('You must enter your transaction Id first.');
        valueTX.length > 0 && fetchManualDeposit();
    };
    const handleCopy = () => {
        if (navigator.clipboard && window.isSecureContext) {
            setCopied(true);
            setCopied2(false);
            return navigator.clipboard.writeText(cryptoValue);
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = cryptoValue;
            textArea.style.position = 'absolute';
            textArea.style.opacity = 0
            document.body.appendChild(textArea);
            textArea.select();
            return new Promise((res, rej) => {
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
                setCopied(true);
                setCopied2(false);
            });
        };
    };
    const handleCopy2 = () => {
        if (navigator.clipboard && window.isSecureContext) {
            setCopied2(true);
            setCopied(false);
            return navigator.clipboard.writeText(address);
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = address;
            textArea.style.position = 'absolute';
            textArea.style.opacity = 0
            document.body.appendChild(textArea);
            textArea.select();
            return new Promise((res, rej) => {
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
                setCopied2(true);
                setCopied(false);
            });
        };
    };
    return (
        <SidebarContainer isOpen={isSubmitting ? 1 : 0}>
            <ModalWrapper>
                <CloseIconWrap onClick={() => { setIsSubmitting(false); setPrice(undefined); setApiError(''); }}>
                    <CloseIcon />
                </CloseIconWrap>
                {price !== undefined &&
                    <ContentWrap style={{ height: '90%' }}>
                        <div style={{ width: '100%' }}>
                            <TimePriceWrap>
                                <Countdown
                                    date={timer}
                                    renderer={renderer}
                                    onComplete={() => {
                                        setTimeout(() => history.go(0), 2000);
                                        setTimer(0);
                                    }}
                                />
                                <PriceWrap>
                                    <h2>1 {crypto} = {price.toFixed(6)} usd</h2>
                                </PriceWrap>
                            </TimePriceWrap>
                            <InstructionWrap>
                                <p>
			            Please send exactly {cryptoValue} {crypto} to the following address in less than 20 minutes.
			        </p>
                            </InstructionWrap>
                            <ValueAddressWrap>
                                <ItemContainer>
                                    <ItemWrap>
                                        <h3>{cryptoValue}</h3>
                                    </ItemWrap>
                                    <RefButton onClick={handleCopy}>
                                        {copied ? 'copied' : 'copy'}
                                    </RefButton>
                                </ItemContainer>
                                <ItemContainer>
                                    <ItemWrap>
                                        <h3>{address}</h3>
                                    </ItemWrap>
                                    <RefButton onClick={handleCopy2}>
                                        {copied2 ? 'copied' : 'copy'}
                                    </RefButton>
                                </ItemContainer>
                            </ValueAddressWrap>
                            <InstructionWrap style={{ marginBottom: '10px' }}>
                                <p>
                                    You must enter your transaction Id here and then press the following button. The confirmation of your payment may take long between 1 hour till 2 days.
                                </p>
                            </InstructionWrap>
                            <div style={{ margin: 'auto', width: '75%' }}>
                                <PtcInputWrap>
                                    <PtcTitleIcon style={{ transform: 'translate(75%, 28%)' }} />
                                    <PtcFormInput
                                        style={{ marginBottom: '0' }}
                                        type='text'
                                        placeholder='Enter your transaction Id.'
                                        onChange={handleChange}
                                        value={valueTX}
                                    >
                                    </PtcFormInput>
                                </PtcInputWrap>
                            </div>
                        </div>
                        {error &&
                            <MsgWrap style={{ width: '80%' }} primary={0}>
                                <MsgText primary={0}>
                                    {error}
                                </MsgText>
                            </MsgWrap>
                        }
                        {apiError.length === 0 && !complete && timer > 0 &&
                            <FormButton
                                type='button'
                                style={{ width: '80%', maxWidth: '500px' }}
                                big={0}
                                primary={1}
                                onClick={handleManualDeposit}
                            >
                                Deposit
                            </FormButton>}
                        {apiError &&
                            <MsgWrap style={{ width: '80%' }} primary={0}>
                                <MsgText primary={0}>
                                    {apiError}
                                </MsgText>
                            </MsgWrap>
                        }
                        {timer === 0 &&
                            <MsgWrap style={{ width: '80%' }} primary={0}>
                                <MsgText primary={0}>
                                    Your time is expired. Please try again.
                                </MsgText>
                            </MsgWrap>
                        }
                        {complete && !apiError &&
                            <MsgWrap style={{ width: '80%' }} primary={1}>
                                <MsgText primary={1}>
                                    Your deposit was successfully submitted.
                                </MsgText>
                            </MsgWrap>
                        }
                    </ContentWrap>
                }
            </ModalWrapper>
        </SidebarContainer >
    )
}
export default Modal;
