import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axios';
import PersonalityInfo from './PersonalityInfo';
import FinancialInfo from './FinancialInfo';
import ChangePassword from './ChangePassword';
import ConfirmChanges from './ConfirmChanges';

const SettingContainer = () => {
    const [info, setInfo] = useState(Object.freeze());
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [values, setValues] = useState();
    const [updateComplete, setUpdateComplete] = useState(false);
    const [updateError, setUpdateError] = useState('');
    const fetchSettingInfo = async () => {
        const { data } = await axiosInstance.get('settings/');
        setInfo(data.info);
        setValues({
            username: data.info.username,
            tron: data.info.tron !== undefined ? data.info.tron : '',
	    tether: data.info.tether !== undefined ? data.info.tether : '',
	    dogecoin: data.info.dogecoin !== undefined ? data.info.dogecoin : '',
	    bitcoin: data.info.bitcoin !== undefined ? data.info.bitcoin : '',
	    toncoin: data.info.toncoin !== undefined ? data.info.toncoin : '',
	    solana: data.info.solana !== undefined ? data.info.solana : '',
	    tetherFP: data.info.tetherFP !== undefined ? data.info.tetherFP : '',
	    bitcoinFP: data.info.bitcoinFP !== undefined ? data.info.bitcoinFP : '',
	    payeer: data.info.payeer !== undefined ? data.info.payeer : '',
            password: '',
            confirm_password: ''
        })
    };
    const fetchUpdateSettingInfo = async () => {
        try {
            await axiosInstance.put('settings/update/', values);
            setUpdateComplete(true);
        } catch (error) {
            error.response && error.response.data.detail ? setUpdateError(error.response.data.detail) : setUpdateError(error.message);
        };
        setIsSubmitting(false);
    };
    useEffect(() => {
        fetchSettingInfo();
    }, []);
    useEffect(() => {
        isSubmitting && !updateComplete && fetchUpdateSettingInfo();
    });
    return (
        <>
            {info !== undefined && values !== undefined &&
                <>
                    <PersonalityInfo
                        info={info}
                        values={values}
                        setValues={setValues}
                        updateComplete={updateComplete}
                    />
                    <FinancialInfo
                        info={info}
                        values={values}
                        setValues={setValues}
                    />
                    <ChangePassword values={values} setValues={setValues} />
                    <ConfirmChanges setIsSubmitting={setIsSubmitting} updateError={updateError} updateComplete={updateComplete} />
                </>
            }
        </>
    )
}
export default SettingContainer;
