import styled from 'styled-components';

export const MsgWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${({ primary }) => (primary ? 'rgba(39, 117, 85, 0.4)' : 'rgba(255, 0, 0, 0.35)')};
    border: 2px solid ${({ primary }) => (primary ? 'rgba(39, 117, 85, 0.6)' : 'rgba(255, 0, 0, 0.6)')};
    border-radius: 7px;
    margin: 10px 0;
`
export const MsgText = styled.span`
    width: 100%;
    background: ${({ primary }) => (primary ? 'rgba(0, 0, 0, 0.25)' : 'rgba(0, 0, 0, 0.4)')};
    border-radius: 7px;
    padding: 7px;
    color: ${({ primary }) => (primary ? 'rgba(1, 219, 187, 1)' : 'rgb(220, 60, 60)')};
    text-shadow: ${({ primary }) => (primary ? ' 0 0 3px rgba(0, 0, 0, 0.5)' : '0 0 0 #000000')};
    font-size: clamp(0.8rem, 2.4vw, 1.05rem);
    letter-spacing: 0.3px;
    word-spacing: 0.7px;
    text-align: center;
`
