import React, { useEffect, useState } from 'react';
import { Wrapper, TitleWrap } from './SmallCardInfoElements';
import { CircleCardWrapper, CircleCard } from './CircleCardChartElements';
import {
    ResponsiveContainer,
    Legend,
    PieChart,
    Pie,
    Cell
} from 'recharts';

const CircleCardChart = ({ allInfo }) => {
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {(percent !== 0 && percent !== 1) ? `${(percent * 100).toFixed(0)}%` : null}
            </text>
        );
    };
    const COLORS = ['rgb(29, 107, 187)', 'rgb(39, 117, 85)', 'rgb(1, 219, 187)', 'rgb(120, 116, 16)', 'rgb(255, 153, 51)', 'rgb(177, 190, 75)', 'rgb(190, 75, 177)', 'rgb(240, 75, 75)'];
    const [smallSize, setSmallSize] = useState(false);
    const closeMainbar = () => {
        if (window.innerWidth < 383) {
            setSmallSize(true);
        } else {
            setSmallSize(false);
        };
    };
    useEffect(() => {
        closeMainbar();
        window.addEventListener('resize', closeMainbar);
        return () => {
            window.removeEventListener('resize', closeMainbar);
        };
    });
    return (
        <Wrapper>
            <CircleCardWrapper>
                <CircleCard>
                    <TitleWrap>
                        <h4>members country</h4>
                    </TitleWrap>
                    <ResponsiveContainer width="90%" height={350}>
                        <PieChart>
                            <Legend
                                verticalAlign={'bottom'}
                                layout={'horizontal'}
                                align={'center'}
                                iconSize={!smallSize ? 10 : 8}
                            />
                            <Pie
                                data={allInfo.country_users_data}
                                labelLine={false}
                                label={renderCustomizedLabel}
                                outerRadius={!smallSize ? 140 : 100}
                                dataKey="value"
                            >
                                {allInfo.country_users_data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </CircleCard>
                <CircleCard>
                    <TitleWrap>
                        <h4>members language</h4>
                    </TitleWrap>
                    <ResponsiveContainer width="90%" height={350}>
                        <PieChart>
                            <Legend
                                verticalAlign={'bottom'}
                                layout={'horizontal'}
                                align={'center'}
                                iconSize={!smallSize ? 10 : 8}
                            />
                            <Pie
                                data={allInfo.lang_users_data}
                                labelLine={false}
                                label={renderCustomizedLabel}
                                outerRadius={!smallSize ? 140 : 100}
                                dataKey="value"
                            >
                                {allInfo.lang_users_data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </CircleCard>
            </CircleCardWrapper>
        </Wrapper>
    )
}
export default CircleCardChart;
