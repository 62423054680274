import styled, { css } from 'styled-components/macro';
import device from '../../constants/breakPoints';
import { Link } from 'react-router-dom';
import { GiReturnArrow, GiNextButton } from 'react-icons/gi';
import { ImNewTab } from 'react-icons/im';
import { TiTimes } from 'react-icons/ti';

export const VideoContainer = styled.div`
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
`
export const VideoHeader = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 11fr 1fr;
    grid-column-gap: 30px;
    align-items: center;;
    background: linear-gradient(135deg, #010606 0% , #1D6BBB 30%, #277555 70%, #010606 105%);
    padding: 5px 15px;
    @media screen and ${device.tn} {
	grid-column-gap: 10px;
    }
`
export const VideoHeaderWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
`
export const Bar = styled.div`
    width: ${props => props.width};
    height: 4px;
    background: #01DBBB;
`
export const VideoPlayContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: rgba(29, 107, 187, 0.2);
    padding-bottom: 25px;
`
export const VideoPlayContent = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 25px;
    margin: auto 10px;
    @media screen and ${device.lg} {
	grid-template-columns: 1fr;
    }
`
export const VideoPlayWrap = styled.div`
    width: ${({ short }) => (short ? '52vw' : '95vw')};
    height: ${({ short }) => (short ? 'calc(95vw * 16/9)' : 'calc(95vw * 9/16)')};
    max-width: 1200px;
    max-height: ${({ short }) => (short ? 'calc(1250px * 9/16)' : 'calc(1100px * 9/16)')};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px auto 0 auto;
    @media screen and ${device.md} {
	width: ${({ short }) => (short ? '78vw' : '98vw')};
	height: ${({ short }) => (short ? 'calc(95vw * 16/9)' : 'calc(45vw * 16/9)')};
    }
    @media screen and ${device.sm} {
	width: 100vw;
	height: ${({ short }) => (short ? 'calc(100vw * 16/9)' : 'calc(70vw * 16/9)')};
    }
`
export const VideoDescription = styled.div`
    width: 80%;
    max-width: 1200px;
    background: linear-gradient(135deg, #010606 0% , #1D6BBB 30%, #277555 70%, #010606 105%);
    border: 1px solid rgba(29, 107, 187, 0.4);
    box-shadow: 0 0 10px rgba(29, 107, 187, 0.7);
    border-radius: 15px;
    padding: 10px 15px;
    margin: 25px auto;
    @media screen and ${device.sm} {
	width: 90%;
    }
    p {
	color: #fff;
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
	font-size: clamp(0.75rem, 2.4vw, 1rem);
	letter-spacing: 0.5px;
	word-spacing: 1.5px;
    }
`
export const DetailWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    grid-column-gap: 25px;
    align-items: center;
    padding: 10px;
    @media screen and ${device.md} {
	padding: 10px 3px;
    }
`
export const Duration = styled.div`
    text-align: center;
    span {
	font-size: clamp(0.75rem, 1.8vw, 1rem);
	color: rgba(1, 219, 187, 0.7);
    }
`
export const DetailTitle = styled.div`
    display: flex;
    align-items: cnter;
    justify-content: center;
    text-align: center;
    h1 {
	font-size: clamp(0.8rem, 2.4vw, 1.2rem);
	color: rgba(1, 219, 187, 1);
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
	text-transform: uppercase;
	letter-spacing: 0.5px;
	word-spacing: 1.5px;
    }
`
export const Pcoin = styled.div`
    text-align: center;
    span {
	font-size: clamp(0.75rem, 1.8vw, 1rem);
	color: rgba(1, 219, 187, 0.7);
    }
`
export const MsgItem = styled.div`
    width: 100%;
    max-width: 512px;
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-column-gap: 15px;
    align-items: center;
    margin: auto;
    @media screen and ${device.md} {
	grid-template-columns: 1fr;
	grid-row-gap: 5px;
	width: 80%;
    }
`
export const VideoButtonsWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const GridWrap = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0;
    align-items: center;
    margin: auto;
    @media screen and ${device.tn} {
	grid-template-columns: 1fr;
    }
`
export const AdLinkWrap = styled.a`
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const LinkWrap = styled(Link)`
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const IconStyle = css`
    color: #fff;
    font-size: 1.3rem;
    transition: 0.3s ease-out;
    width: 52px;
    &:hover {
	color: #01DBBB;
	transition: 0.3s ease-out;
    }
`
export const OpenAds = styled(ImNewTab)`
    ${IconStyle};
`
export const Return = styled(GiReturnArrow)`
    ${IconStyle};
`
export const Next = styled(GiNextButton)`
    ${IconStyle};
    font-size: 1.15rem;
    cursor: pointer;
`
export const NumberWrap = styled.div`
    margin: 5px auto;
    span {
	color: #01DBBB;
	font-size: clamp(0.8rem, 2.4vw, 1rem);
	font-weight: 700;
	letter-spacing: 2px;
    }
`
export const VideoTestForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 15px;
`
export const StatusTestMsg = styled.div`
    width: 100%;
    background: rgba(39, 117, 85, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
`
export const ModalContainer = styled.div`
    position: fixed;
    z-index: 98;
    width: 100%;
    height: 100vh;
    background: rgba(1, 6, 6, 0.9);
    display: flex;
    justify-content: center;
    left: 0;
    transition: 0.3s ease-in-out;
    overflow: hidden;
    top: ${({ isOpen }) => (isOpen ? '0' : '-120%')};
`
export const ModalWrapper = styled.div`
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);
    width: 80%;
    height: 77%;
    background: rgba(1, 6, 6, 1);
    color: #fff;
    overflow-y: auto;
    margin: auto;
    @media screen and ${device.sm} {
	width: 95%;
    }    
    &::-webkit-scrollbar {
	width: 7px;
	height: 10px;
    }
    &::-webkit-scrollbar-track {
	background: rgba(256, 256, 256, 0.2);
	box-shadow: inset 0 0 4px rgba(29, 107, 187, 0.7);
    }
    &::-webkit-scrollbar-thumb {
	background: rgba(29, 107, 187, 0.7);
    }
    &::-webkit-scrollbar-thumb:hover {
	background: rgba(29, 107, 187, 0.8);
    }
`
export const CloseIconWrap = styled.div`
    text-align: right;
`
export const CloseIcon = styled(TiTimes)`
    color: #fff;
    font-size: 1.8rem;
    cursor: pointer;
`
