import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const Container = styled.section`
    width: 100%;
    height: 100vh;
    min-height: 580px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #010606;
`
export const Wrapper = styled.div`
    width: 60%;
    height: 80%;
    background: rgba(39, 117, 85, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;

    @media screen and ${device.md} {
        width: 75%;
    };

    @media screen and ${device.sm} {
        width: 95%;
    };
`
export const Wrap = styled.div`
    width: 100%;
    height: 100%;
`
export const ToolsWrap = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    background: rgba(29, 107, 187, 0.75);
    border: 1px solid rgba(29, 107, 187, 0.5);
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(29, 107, 187, 0.7);
    padding: 10px;
    margin-bottom: 10px;
`
export const QuestionWrap = styled.div`
    p {
        color: #fff;
        font-size: clamp(0.85rem, 2vw, 1.1rem);
        font-weight: 400;
        letter-spacing: 1px;
        word-spacing: 1.8px;
        text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    }
`
export const ButtonsWrap = styled.div`
    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-top: 15px;
    @media screen and ${device.lg} {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    };
    @media screen and ${device.md} {
        grid-template-columns: 1fr 1fr 1fr;
    };
    @media screen and ${device.tn} {
	grid-template-columns: 1fr 1fr;
    };
`
export const DButtonsWrap = styled.div`
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 15px;

    @media screen and ${device.md} {
        grid-column-gap: 25px;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 20px;
    };
`
export const FButtonsWrap = styled.div`
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr;
    margin-top: 15px;

`
export const Buttons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(1, 219, 187, 0.7);
    cursor: pointer;
    text-align: center;

    @media screen and ${device.sm} {
        width: 55px;
        height: 55px;
    };
`
export const ButtonsName = styled.div`
    background: ${({ itemActive }) => (itemActive ? '#01DBBB' : 'rgba(1, 219, 187, 0.35)')};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: clamp(0.55rem, 1.8vw, 0.65rem);
    text-transform: uppercase;
    text-shdow: 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    transition: 0.3s ease-in-out;

    &:hover {
        transform: scale(1.09);
        background: ${({ itemActive }) => (itemActive ? '#01DBBB' : '#277555')};
        transition: 0.3s ease-in-out;
    };
`
export const ResultWrap = styled.div``
export const ButtonWrap = styled.div`
    z-index:2;
    width: 40%;
    display: flex;
    justify-content: center;
`
