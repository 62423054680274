import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios';
import { StackContainer, StackWrap, TitleWrap } from '../Stack/StackElements';
import { Table, HeadWrap, HeadRow, Header, BodyWrap, BodyRow, Cell } from '../ContestOffer/ContestElements';
import { LotteryHeaderWrap, LotteryInfo, LotteryTitle } from '../Lottery/LotteryElements';
import { MsgWrap, MsgText } from '../MessageElements';
import { Circle } from '../Loading';
import moment from 'moment';
import { HelpIcon, HelpWrap } from '../HelpElements';
import { RewardShower, BaseReward, ImgWrap, InfinityImage } from '../ContestOffer/ContestElements';
import { slUserStatus, slUserTableHead, slUserStatusTableHead, slUserStatusTableBody, slDeterminePriceForAssistance } from '../../data/slUserStatusData';
import { LevelContainer, LevelWrap, ProgressBarWrap, ProgressBar, Desc1, Desc2, Ring, FilterContainer, ButtonWrap, ButtonFilter } from '../OfferUserStatus/OfferUserStatusElements';
import { FilterWraper, ArticleWrap, SelectLabel, SelectSL, OptionSLList, InputSectionWrap, SectionNumberWrap, InputWrap, UplaodContainer, ImgInput, ImgSectionWrap, ImgSection, DescInputWrap, TextIcon, DescInput, PlusContainer, PlusWrap } from './SLUserStatusElements';
import { TinyFormButton } from '../ButtonElements';
import { DownIcon, UpIcon } from '../HelpDesk/FAQElements';
import AssistanceCard from './AssistanceCard';
import { CardContainer } from './AssistanceCardElements';

const SLUserStatus = () => {
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [info, setInfo] = useState();
    const [infoStartCompose, setInfoStartCompose] = useState();
    const [apiError, setApiError] = useState('');
    const [apiError2, setApiError2] = useState('');
    const [apiError3, setApiError3] = useState('');
    const [showHelp, setShowHelp] = useState(false);
    const [answer, setAnswer] = useState(Object.freeze(''));
    const [submited, setSubmited] = useState(false);
    const [formError, setFormError] = useState('');
    const [complete, setComplete] = useState(false);
    const [arrowClick, setArrowClick] = useState(false);
    const [codeSL, setCodeSL] = useState('');
    const [nameSLIndex, setNameSLIndex] = useState();
    const [nextClick, setNextClick] = useState(false);
    const fileFormats = ['image/jpeg', 'image/png', 'image/gif'];
    const hiddenFileInput = React.useRef(null);
    const [numberSectionList, setnumberSectionList] = useState([1, 2])
    const [activeSectionIndex, setActiveSectionIndex] = useState();
    const [desc, setDesc] = useState(Object.freeze({
	desc1: '',
	desc2: '',
	desc3: '',
	desc4: '',
	desc5: '',
	desc6: '',
    }));
    const [descError, setDescError] = useState({
	desc1: '',
	desc2: '',
	desc3: '',
	desc4: '',
	desc5: '',
	desc6: '',
    });
    const [file, setFile] = useState({
	file1: '',
	file2: '',
	file3: '',
	file4: '',
	file5: '',
	file6: '',
    });
    const [fileSelected, setFileSelected] = useState(Object.freeze({
	file1: '',
	file2: '',
	file3: '',
	file4: '',
	file5: '',
	file6: '',
    }));
    const [fileError, setFileError] = useState({
	file1: '',
	file2: '',
	file3: '',
	file4: '',
	file5: '',
	file6: '',
    });
    const [price, setPrice] = useState();
    const [priceIndex, setPriceIndex] = useState();
    const [composeDone, setComposeDone] = useState(false);
    const [apiError4, setApiError4] = useState('');
    useEffect(() => {
	const fetchSLUserStatus = async () => {
	    try {
		const { data } = await axiosInstance.get('shortlink/status/');
		setInfo(data);
		data.info.level === 5 && data.info.assistant_sl === 'C' ? fetchSLAssistantStartCompose() : setLoading(false);
	    } catch (error) {
		error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
		setLoading(false);
	    };
	};
	fetchSLUserStatus();
    }, []);
    const handleClick = () => {
	setShowHelp(!showHelp);
    };
    const handleFilterClick = (e) => {
	const ANSWER = e.target.getAttribute('value');
	if (ANSWER && answer !== ANSWER) {
	    setAnswer(ANSWER);
	    setFormError('');
	};
    };
    const handleSubmit = (e) => {
	e.preventDefault();
	if (answer) {
	    setSubmited(true);
	    fetchSubmitForm();
	} else {
	    setFormError('Please select your answer first!')
	};
    };
    const fetchSubmitForm = async () => {
	setLoading2(true);
	try {
	    await axiosInstance.post('shortlink/assistant/', { 'answer': answer });
	    setComplete(true);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError2(error.response.data.detail) : setApiError2(error.message);
	};
	setLoading2(false);
    };
    const handleSelectSL = () => {
	setArrowClick(!arrowClick);
    };
    const handleClickSL = (code, index) => {
	setCodeSL(code);
	setNameSLIndex(index);
    };
    const handleNextClick = () => {
	setNextClick(true);
    };
    const fetchSLAssistantStartCompose = async () => {
	try {
	    const { data } = await axiosInstance.get('shortlink/assistant/start-compose/');
	    setInfoStartCompose(data);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError3(error.response.data.detail) : setApiError3(error.message);
	};
	setLoading(false);
    };
    const handleUploadClick = (i) => {
	setActiveSectionIndex(i);
    };
    const handleDimensionCheck = (e) => {
	var img = document.createElement("img");
	img.onload = function () {
	    if (this.width > 1080 || this.height > 1080) {
		setFileError({
		    ...fileError,
		    ['file' + activeSectionIndex]: 'The maximum size of images is 1080 x 1080'
		});
	    } else {
		setFileSelected(e.target.files[0]);
		setFileSelected({
		    ...fileSelected,
		    ['file' + activeSectionIndex]: e.target.files[0]
		});
	    };
	};
	var reader = new FileReader();
	reader.onloadend = function (ended) {
	    img.src = ended.target.result;
	    setFile({
		...file,
		['file' + activeSectionIndex]: img.src
	    });
	}
	reader.readAsDataURL(e.target.files[0]);
    };
    const handleFileChange = (e) => {
	setFileError({
	    ...fileError,
	    ['file' + activeSectionIndex]: ''
	});
	if (e.target.files.length > 0) {
	    const uplaodFileType = e.target.files[0].type;
	    if (uplaodFileType && fileFormats.includes(uplaodFileType)) {
		const fileSize = e.target.files[0].size / 1024;
		if (fileSize <= 1024) {
		    handleDimensionCheck(e);
		} else {
		    setFileError({
			...fileError,
			['file' + activeSectionIndex]: 'This file is too large to uplaod. The maximum supported size is 1024 kb.'
		    });
		}
	    } else {
		setFileError({
		    ...fileError,
		    ['file' + activeSectionIndex]: 'Not supported the extension of the file, please uplaod jpej family, png and gif formats.'
		});
	    };
	};
    };
    const handleChange = (e) => {
	setDescError({
	    ...descError,
	    ['desc' + activeSectionIndex]: ''
	});
	const { name, value } = e.target;
	setDesc({
	    ...desc,
	    [name]: value,
	});
    };
    const handlePlusClick = () => {
	setnumberSectionList(numberSectionList.concat(numberSectionList.length + 1));
    };
    const handlePriceCLick = (PRICE, index) => {
	setPrice(PRICE);
	setPriceIndex(index);
	ValidateForm();
    };
    const ValidateForm = () => {
	Object.keys(file).map((img, index) => file[img].length > 0 && desc['desc' + (index + 1)].length === 0 && setDescError({ ...descError, ['desc' + (index + 1)]: 'Please enter a description for your image!' }));
	Object.keys(desc).map((d, index) => desc[d].length > 0 && file['file' + (index + 1)].length === 0 && setFileError({ ...fileError, ['file' + (index + 1)]: 'Please uplaod an image!' }));
    };
    const handleCreateSubmit = (e) => {
	e.preventDefault();
	!loading3 && fetchSLAssistantEndCompose();
    };
    const fetchSLAssistantEndCompose = async () => {
	setLoading3(true);
	try {
	    let fd = new FormData();
	    Object.entries(fileSelected).map(([key, value]) => fd.append(key, value));
	    Object.entries(desc).map(([key, value]) => fd.append(key, value));
	    fd.append('sl_model', codeSL);
	    fd.append('pcoin', price);
	    await axiosInstance.post('shortlink/assistant/end-compose/', fd, { headers: { "Content-Type": "multipart/form-data" } });
	    setComposeDone(true);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError4(error.response.data.detail) : setApiError4(error.message);
	};
	setLoading3(true);
    };
    return (
	<>
	    <StackContainer primary={0} style={{ top: '0' }}>
	        <StackWrap primary={0}>
	    	    <TitleWrap primary={0}>
	        	<h2>Your shortlink Status</h2>
	        	<HelpIcon style={{ top: '-5px' }} onClick={() => handleClick()} show={showHelp ? 1 : 0} />
	    	    </TitleWrap>
	    	    {loading &&
		        <div style={{ width: '100%', height: '90vh', display: 'flex', alignItems: 'center' }}>
		            <Circle style={{ margin: 'auto', width: '100px', height: '100px' }} />
		        </div>
	    	    }
	    	    {apiError &&
			<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
			    <MsgText primary={0}>
			        {apiError}
		            </MsgText>
			</MsgWrap>
	    	    }
	    	    {!loading && !apiError && info !== undefined &&
			<>
			    {!showHelp &&
				<LotteryHeaderWrap>
				    <LotteryInfo>
					<LevelContainer>
				    	    {slUserStatus.map((item, index) => (
						<LevelWrap key={index}>
						    <Desc1 active={item.level === info.info.level ? 1 : 0}>{item.up}</Desc1>
						    <Ring active={(item.level < info.info.level) || (item.level === info.info.level) ? 1 : 0} />
						    <ProgressBarWrap>
						        <ProgressBar width={item.pc <= info.info.sl_earn && item.level === info.info.level && info.info.level !== 5 ? (String((info.info.sl_earn - item.pc) / (item.next_round - item.pc) * 100)) + '%' : (item.level === info.info.leve) || (item.level < info.info.level) || info.info.level === 5 ? '100%' : '0'} />
						    </ProgressBarWrap>
						    <Desc2 active={item.level === info.info.level ? 1 : 0}>{item.down}</Desc2>
						    <Desc2 active={item.level === info.info.level ? 1 : 0}>{item.down2}</Desc2>
						</LevelWrap>
					    ))}
				    	</LevelContainer>
				        {info.info.level === 5 &&
					    <ImgWrap style={{ margin: '0 auto 5px auto' }}>
					        <InfinityImage src='https://icefaucet.com/media/help/blog/SL_level_5.png' alt='level 5' />
					    </ImgWrap>
				        }
				        <RewardShower>
				            <BaseReward>
				                <h2>Total Earnings</h2>
				            </BaseReward>
				            <BaseReward style={{ background: 'rgba(29, 107, 187, 0.35)', width: '50%', height: '100%', borderRadius: '0 10px 10px 0' }}>
				                <h2 style={{ color: '#fff' }}>{info.info.sl_earn} PC</h2>
				            </BaseReward>
				        </RewardShower>
				    	<RewardShower>
				            <BaseReward>
				                <h2>Number Complete</h2>
				            </BaseReward>
				            <BaseReward style={{ background: 'rgba(29, 107, 187, 0.35)', width: '50%', height: '100%', borderRadius: '0 10px 10px 0' }}>
				                <h2 style={{ color: '#fff' }}>{info.info.number_complete}</h2>
				            </BaseReward>
				        </RewardShower>
				    </LotteryInfo>
				</LotteryHeaderWrap>
			    }
			    {showHelp &&
				<HelpWrap style={{ marginBottom: '10px', flexDirection: 'column' }} show={showHelp ? 1 : 0}>
				    <p>
					The Total Earnings(TE) is your total earnings from the shortlink.<br />
					More TE makes your level higher.<br />
					Higher level, more earnings.<br />
					Win the reward when your level higher.<br />
					Beta users, get leveling up 1.5X faster.<br />
					Alpha users, get leveling up 2.0X faster.<br />
					In level 5, the user can become an assistant and help others to earn.
				    </p>
				    <Table style={{ margin: '20px auto 0 auto' }}>
					<HeadWrap>
					    <HeadRow>
					        {slUserStatusTableHead.map((header, index) => (
						    <Header key={index}>
							{header}
						    </Header>
						))}
				            </HeadRow>
					</HeadWrap>
					<BodyWrap>
					    {slUserStatusTableBody.map((row, index) => (
						<BodyRow key={index}>
						    <Cell>
							{row.TE_required}
						    </Cell>
						    <Cell>
							{row.level}
						    </Cell>
						    <Cell>
							{row.ice_bonus}
						    </Cell>
						    <Cell>
							{row.reward}
						    </Cell>
						</BodyRow>
					    ))}
				        </BodyWrap>
				    </Table>
				</HelpWrap>
			    }
			    {info.data.length > 0 &&
				<Table style={{ marginTop: '25px' }}>
				    <HeadWrap>
					<HeadRow>
					    {slUserTableHead.map((header, index) => (
						<Header key={index}>
						    {header}
						</Header>
					    ))}
				        </HeadRow>
				    </HeadWrap>
				    <BodyWrap>
					{info.data.map((row, index) => (
					    <BodyRow key={index}>
						<Cell>
						    {row.name}
						</Cell>
						<Cell>
						    {row.pcoin} PC
						</Cell>
						<Cell>
						    {moment(row.created).fromNow()}
						</Cell>
					    </BodyRow>
					))}
				    </BodyWrap>
				</Table>
			    }
			    {info.info.level === 5 && (info.info.assistant_sl === 'P' || info.info.assistant_sl === 'M') &&
				<form style={{ width: '100%' }} action='#' onSubmit={handleSubmit}>
				    <LotteryHeaderWrap>
					<LotteryInfo>
					    <LotteryTitle style={{ display: 'flex', justifyContent: 'center', borderRadius: '15px' }}>
					        <h2>shortlink assistant</h2>
					    </LotteryTitle>
					    {info.info.assistant_sl === 'P' &&
						<FilterContainer>
						    <p>Do you want to become an assistant and earn more money? For more information press the "article" button.</p>
						    <FilterWraper>
							<ButtonWrap>
							    <ButtonFilter value={'yes'} onClick={handleFilterClick} itemActive={answer === 'yes' ? 1 : 0}>yes</ButtonFilter>
							</ButtonWrap>
							<ButtonWrap>
							    <ButtonFilter value={'no'} onClick={handleFilterClick} itemActive={answer === 'no' ? 1 : 0}>no</ButtonFilter>
							</ButtonWrap>
						        <ArticleWrap to={'/help/blog/82EBC645E96E471'}>
						            <ButtonFilter>article</ButtonFilter>
						        </ArticleWrap>
						    </FilterWraper>
						</FilterContainer>
					    }
				    	    {formError &&
						<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
						    <MsgText primary={0}>
							{formError}
						    </MsgText>
						</MsgWrap>
					    }
				    	    {!submited && info.info.assistant_sl === 'P' &&
						<TinyFormButton style={{ width: '200px', marginBottom: '15px' }} type='submit' primary={1} big={0}>
						    submit
						</TinyFormButton>
					    }
				    	    {loading2 &&
						<div style={{ margin: '25px auto' }}>
						    <Circle style={{ margin: 'auto', width: '20px', height: '20px' }} />
						</div>
					    }
				    	    {!loading2 && apiError2 &&
						<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
						    <MsgText primary={0}>
							{apiError2}
						    </MsgText>
						</MsgWrap>
					    }
				    	    {!loading2 && !apiError2 && complete &&
						<MsgWrap primary={1} style={{ width: '80%', margin: '15px auto 25px auto' }}>
						    <MsgText primary={1}>
							Your request has been successfully sent. We need more time to check your account. Please wait...
						    </MsgText>
						</MsgWrap>
					    }
				    	    {info.info.assistant_sl === 'M' &&
						<MsgWrap primary={1} style={{ width: '80%', margin: '15px auto 25px auto' }}>
						    <MsgText primary={1}>
							Your request has been successfully sent. We need more time to check your account. Please wait...
						    </MsgText>
						</MsgWrap>
					    }
				    	</LotteryInfo>
				    </LotteryHeaderWrap>
				</form>
			    }
	            	    {infoStartCompose !== undefined && Object.keys(infoStartCompose).length > 0 &&
				<form style={{ width: '100%' }} action='#' onSubmit={handleCreateSubmit}>
			    	    <LotteryHeaderWrap>
					<LotteryInfo>
			    	    	    <LotteryTitle style={{ display: 'flex', justifyContent: 'center', borderRadius: '15px' }}>
			                	<h2>Create an assistance</h2>
			            	    </LotteryTitle>
			    	    	    {!nextClick &&
						<FilterContainer>
					    	    <p>First, please select a shortlink to which you want to write assistance. Press on the following box to open the menu.</p>
					    	    <SelectLabel onClick={handleSelectSL}>
					        	<h4>Press to select a shortlink:</h4>
					        	{arrowClick ? <UpIcon /> : <DownIcon />}
					    	    </SelectLabel>
					    	    {arrowClick &&
							<SelectSL>
						    	    {infoStartCompose.info.map((sl, index) => (
								<OptionSLList key={index} onClick={() => handleClickSL(sl.code, index)} active={index === nameSLIndex ? 1 : 0} disable={sl.user_compose ? 1 : 0}>
							    	    <h4>{sl.name}</h4>
							    	    <span>{sl.number_assistance} {sl.number_assistance > 1 ? 'assistances' : 'assistance'}</span>
								</OptionSLList>
						    	    ))}
							</SelectSL>
					    	    }
					    	    {!apiError3 && codeSL && !nextClick &&
							<TinyFormButton style={{ width: '200px', margin: '15px auto 0 auto' }} type='button' onClick={handleNextClick} primary={1} big={0}>
						    	    next
							</TinyFormButton>
					            }
					    	    {Object.keys(infoStartCompose.info2).length > 0 &&
							<p style={{ marginTop: '30px' }}>You can edit and manage your assistance here. For every shortlink, you can write only one assistance.</p>
					    	    }
					    	    <CardContainer>
					        	{infoStartCompose.info2.map((assistance, index) => (
						    	    <AssistanceCard key={index} assistance={assistance} index={index} />
						        ))}
					    	    </CardContainer>
						</FilterContainer>
				    	    }
			    	    	    {apiError3 &&
						<MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
					    	    <MsgText primary={0}>
					        	{apiError3}
					    	    </MsgText>
						</MsgWrap>
				    	    }
			    	    	    {nextClick &&
						<FilterContainer>
					    	    <p>
							Your assistance needs at least 2 sections. Every section must have an image and description of that image. 
							You can continue your assistance up to 6 sections. Images should be less than 1 MB in size and image dimensions 
					    		should be smaller than 1080 x 1080 pixels.
					    	    </p>
					            {numberSectionList.map((i, index) => (
						        <InputSectionWrap key={index}>
							    <SectionNumberWrap>
							        <h4>
							    	    section<br />
							            {i}
							        </h4>
							    </SectionNumberWrap>
							    <InputWrap>
							        <UplaodContainer>
							    	    <ImgInput
							                type='file'
							                multiple
							                name={'file' + i}
							                accept='image/jpeg, image/png, image/gif'
							                onChange={handleFileChange}
							                ref={hiddenFileInput}
							                style={{ display: 'none' }}
							             />
							            {file['file' + i] && !fileError['file' + i] &&
								        <ImgSectionWrap>
								            <ImgSection src={file['file' + i]} alt='section image' />
								        </ImgSectionWrap>
								    }
							            <TinyFormButton type='button' primary={1} big={0} style={{ width: '160px', marginTop: '5px' }} onClick={() => { hiddenFileInput.current.click(); handleUploadClick(i) }}>
							                uplaod your image
							            </TinyFormButton>
							        </UplaodContainer>
							        <DescInputWrap>
							            <TextIcon />
							            <DescInput
							                multiple
							                name={'desc' + i}
							                placeholder='Enter your description about your image.'
							                onChange={handleChange}
							               value={desc.i}
							            />
							            {fileError['file' + i] &&
								        <MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
								            <MsgText primary={0}>
								                {fileError['file' + i]}
								            </MsgText>
								        </MsgWrap>
								    }
							            {descError['desc' + i] &&
								        <MsgWrap primary={0} style={{ width: '80%', margin: '15px auto 25px auto' }}>
									    <MsgText primary={0}>
									        {descError['desc' + i]}
								            </MsgText>
								        </MsgWrap>
								    }
							        </DescInputWrap>
							    </InputWrap>
						        </InputSectionWrap>
						    ))}
					            {numberSectionList.length <= 5 &&
						        <PlusContainer>
							    <PlusWrap onClick={handlePlusClick}>
							        <span>+</span>
							    </PlusWrap>
						        </PlusContainer>
						    }
					    	    {file.file1 && desc.desc1 && file.file2 && desc.desc2 &&
						        <FilterContainer>
							    <p>Set a price for your assistance. Users have to purchase your assistance to access them. We suggest you publish your first assistance for free and set the price according to the number of sections of your assistance. You can change your price later.</p>
							    <FilterWraper>
							        {slDeterminePriceForAssistance.map((button, index) => (
								    <ButtonWrap key={index}>
								        <ButtonFilter onClick={() => handlePriceCLick(button.price, index)} itemActive={index === priceIndex ? 1 : 0}>{button.name}</ButtonFilter>
								    </ButtonWrap>
							        ))}
						            </FilterWraper>
						        </FilterContainer>
					            }
					            {composeDone &&
						        <MsgWrap primary={1} style={{ width: '80%', margin: '15px auto auto auto' }}>
							    <MsgText primary={1}>
							        Thank you for creating assistance. After your assistance is confirmed, you will earn 500 PC.
							    </MsgText>
						        </MsgWrap>
					    	    }
					    	    {apiError4 &&
						        <MsgWrap primary={0} style={{ width: '80%', margin: '15px auto auto auto' }}>
							    <MsgText primary={0}>
							        {apiError4}
						            </MsgText>
						        </MsgWrap>
					            }
					            {price !== undefined && !composeDone &&
						        <TinyFormButton style={{ width: '200px', margin: '15px auto' }} type='submit' primary={1} big={0}>
							    {!loading3 ? 'submit all' : <Circle style={{ margin: 'auto', width: '15px', height: '15px' }} />}
						        </TinyFormButton>
					            }
					        </FilterContainer>
					    }
			                </LotteryInfo>
				    </LotteryHeaderWrap>
			        </form>
			    }
	                </>
	            }
	        </StackWrap>
	    </StackContainer >
	</>
    )
}
export default SLUserStatus;
