export const statisticsMessageData = {
    msg: 'Data is updated every 6 hours.'
};

export const statisticsMessageLangData = [
    {
        lang: 'es',
        info: {
            msg: 'Los datos se actualizan cada 6 horas.'
        }
    },
    {
        lang: 'ru',
        info: {
            msg: 'Данные обновляются каждые 6 часов.'
        }
    },
    {
        lang: 'pt',
        info: {
            msg: 'Os dados são atualizados a cada 6 horas.'
        }
    },
    {
        lang: 'fr',
        info: {
            msg: 'Les données sont mises à jour toutes les 6 heures.'
        }
    },
    {
        lang: 'de',
        info: {
            msg: 'Die Daten werden alle 6 Stunden aktualisiert.'
        }
    },
    {
        lang: 'it',
        info: {
            msg: 'I dati vengono aggiornati ogni 6 ore.'
        }
    }
];
