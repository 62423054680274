import React from 'react';
import { TypeWrap, TypeCard, ImageWrap, Image, WContext, WContextItem, CryptoWalletContainer, CryptoContainer, CryptoWrap, Crypto } from './CardsElements';

const Cards = ({ info, crypto }) => {
    return (
	<TypeWrap>
	    {info.map((item, index) => (
		<TypeCard key={index}>
		    <ImageWrap>
		        <Image src={item.src} alt={item.alt} title={item.title} />
		    </ImageWrap>
		    <WContext>
		        <WContextItem>
		            <span>min withdraw:</span>
		            <span>{item.min}</span>
		        </WContextItem>
		        <WContextItem>
		            <span>Fee:</span>
		            <span>{item.fee}</span>
		        </WContextItem>
		        {item.type === 'W' &&
			    <WContextItem style={{ justifyContent: 'center' }}>
				<span>less than a day</span>
			    </WContextItem>
			}
		        {(item.type === 'P' || item.type === 'F') &&
			    <WContextItem style={{ justifyContent: 'center' }}>
				<span>instant</span>
			    </WContextItem>
			}
		    </WContext>
		    {item.type === 'W' &&
			<CryptoWalletContainer>
			    {Object.keys(crypto.manual).length > 0 && crypto.manual.map((crypto, index) => (
				<CryptoWrap key={index}>
				    <Crypto src={crypto.image} alt={crypto.name} />
				</CryptoWrap>
			    ))}
			</CryptoWalletContainer>
		    }
		    {item.type === 'P' &&
			<CryptoContainer>
			    {Object.keys(crypto.payeer).length > 0 && crypto.payeer.map((crypto, index) => (
				<CryptoWrap key={index}>
				    <Crypto src={crypto.image} alt={crypto.name} />
				</CryptoWrap>
			    ))}
			</CryptoContainer>
		    }
		    {item.type === 'F' &&
			<CryptoContainer style={{ width: '50%' }}>
			    {Object.keys(crypto.fp).length > 0 && crypto.fp.map((crypto, index) => (
				<CryptoWrap key={index}>
				    <Crypto src={crypto.image} alt={crypto.name} />
				</CryptoWrap>
			    ))}
			</CryptoContainer>
		    }
		</TypeCard>
	    ))}
	</TypeWrap>
    )
}
export default Cards;
