import React from 'react';
import { StackContainer, StackWrap, TitleWrap } from '../Stack/StackElements';
import { Table, HeadWrap, HeadRow, Header, BodyWrap, BodyRow, Cell } from '../ContestOffer/ContestElements';
import { LotteryHeaderWrap, LotteryInfo } from '../Lottery/LotteryElements';
import moment from 'moment';
import { RewardShower, BaseReward, ImgWrap, InfinityImage } from '../ContestOffer/ContestElements';
import { refrUserStatus, refrUserTableHead } from '../../data/stackData';
import { LevelContainer, LevelWrap, ProgressBarWrap, ProgressBar, Desc1, Desc2, Ring } from '../OfferUserStatus/OfferUserStatusElements';
import ProfitBox from './ProfitBox';

const RefrUserStatus = ({ info }) => {
    return (
	<>
	    <StackContainer primary={0} style={{ top: '0' }}>
	        <StackWrap primary={0}>
	            <TitleWrap primary={0}>
	                <h2>Your stake Status</h2>
	            </TitleWrap>
	    	    {info !== undefined &&
			<>
			    <LotteryHeaderWrap>
			        <LotteryInfo>
			            <LevelContainer>
			            	{refrUserStatus.map((item, index) => (
					    <LevelWrap key={index}>
						<Desc1 active={item.level === info.info.level ? 1 : 0}>{item.up}</Desc1>
						<Ring active={(item.level <= info.info.level) ? 1 : 0} />
						<ProgressBarWrap>
						    <ProgressBar width={item.level === info.info.level && item.icecoin < info.info.icecoin && info.info.level !== 6 ? (String((info.info.icecoin - item.icecoin) / (item.next_round - item.icecoin) * 100)) + '%' : (item.level === info.info.leve) || (item.level < info.info.level) || info.info.level === 6 ? '100%' : '0'} />
						</ProgressBarWrap>
						<Desc2 active={item.level === info.info.level ? 1 : 0}>{item.down}</Desc2>
					    </LevelWrap>
					))}
			            </LevelContainer>
			    	    {info.info.level === 6 &&
					<ImgWrap style={{ margin: '0 auto 5px auto' }}>
					    <InfinityImage src='https://icefaucet.com/media/help/blog/master_node.png' alt='master-node' />
					</ImgWrap>
				    }
			    	    <RewardShower>
			        	<BaseReward>
			            	    <h2>Your icecoins</h2>
			        	</BaseReward>
			        	<BaseReward style={{ background: 'rgba(29, 107, 187, 0.35)', width: '50%', height: '100%', borderRadius: '0 10px 10px 0' }}>
			            	    <h2 style={{ color: '#fff' }}>{info.info.icecoin}</h2>
			        	</BaseReward>
			    	    </RewardShower>
			    	    <RewardShower>
			            	<BaseReward>
			                    <h2>Freezed icecoins</h2>
			                </BaseReward>
			                <BaseReward style={{ background: 'rgba(29, 107, 187, 0.35)', width: '50%', height: '100%', borderRadius: '0 10px 10px 0' }}>
			                    <h2 style={{ color: '#fff' }}>{info.info.freez_icecoin}</h2>
			                </BaseReward>
			            </RewardShower>
			    	</LotteryInfo>
			    </LotteryHeaderWrap>
			    <ProfitBox />
			    {info.data.length > 0 &&
				<Table style={{ marginTop: '25px' }}>
				    <HeadWrap>
				        <HeadRow>
				            {refrUserTableHead.map((header, index) => (
						<Header key={index}>
						    {header}
						</Header>
					    ))}
				        </HeadRow>
				    </HeadWrap>
				    <BodyWrap>
				        {info.data.map((row, index) => (
					    <BodyRow key={index}>
						<Cell>
						    {row.icecoin}
						</Cell>
						<Cell>
						    {row.refr_time} months
						</Cell>
						<Cell>
						    {row.refr_status === 'F' ? 'freezing' : row.refr_status === 'W' ? 'working' : row.refr_status === 'E' ? 'expired' : 'deleted'}
						</Cell>
						<Cell>
						    {moment(row.created).fromNow()}
						</Cell>
					    </BodyRow>
					))}
				    </BodyWrap>
				</Table>
			    }
			</>
		    }
	        </StackWrap>
	    </StackContainer>
	</>
    )
}
export default RefrUserStatus;

