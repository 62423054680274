import React from 'react';
import { NSCWarp, NSCHeaderWrap, NSCImgContainer, NSCImgBackground, NSCImgWrap, SectionName, NSCEndTimer, NSCTitle, Timer } from './NewSpecialCardElements';
import Countdown, { zeroPad } from 'react-countdown';

const NewSpecialCard = ({ newSpecialCard }) => {
    const rendererBig = ({ days, hours, minutes, seconds }) => {
	return <Timer>{zeroPad(days)} days + {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</Timer>;
    };
    const renderer = ({ hours, minutes, seconds }) => {
	return <Timer>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</Timer>;
    };
    return (
	<NSCWarp>
	    <NSCImgContainer>
		<NSCImgBackground>
		    <img src={newSpecialCard.image} alt={newSpecialCard.title} />
		</NSCImgBackground>
		<NSCImgWrap>
		    <NSCHeaderWrap>
		        <SectionName>
		            <h3>New Special Card</h3>
		        </SectionName>
		        <NSCTitle>
		            <h2>{newSpecialCard.title}</h2>
		        </NSCTitle>
		    </NSCHeaderWrap>
		    <img src={newSpecialCard.image} alt={newSpecialCard.title} />
		    {newSpecialCard.end_time_second > 0 &&
			<NSCEndTimer>
			    <Countdown date={Date.now() + newSpecialCard.end_time_second * 1000} renderer={newSpecialCard.end_time_second > 24 * 3600 ? rendererBig : renderer} />
			</NSCEndTimer>
		    }
		</NSCImgWrap>
	    </NSCImgContainer>
	</NSCWarp>
    )
}
export default NewSpecialCard;
