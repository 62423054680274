import { GiCheckMark } from 'react-icons/gi';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';

export const adsToolsIntro = [
    {
        mainTitle: 'Ads properties',
        mainImg: require('../images/tools-ads.png').default,
        mainAlt: 'ads tools Introduction',
        tools: [
            {
                title: 'Paid To Click',
                icon: <IoMdCheckmarkCircleOutline />,
                desc1: 'Forced watching',
                desc2: 'Width screen size protection',
                desc3: 'Social media attachment',
                desc4: '256 characters description',
            },
            {
                title: 'Surfing',
                icon: <IoMdCheckmarkCircleOutline />,
                desc1: 'Display in original URL of the ads',
                desc2: 'Width screen size protection',
                desc3: 'Social media attachment',
                desc4: '256 characters description',
            },
            {
                title: 'Video',
                icon: <IoMdCheckmarkCircleOutline />,
                desc1: 'Only Youtube links',
                desc2: 'Forced watching',
                desc3: 'Width screen size protection',
                desc4: '',
   	    },
            {
                title: 'Auto',
                icon: <IoMdCheckmarkCircleOutline />,
                desc1: 'The cheapest one',
                desc2: 'Width screen size protection',
                desc3: '',
                desc4: '',
            },
	    {
		title: 'Ice Story',
		icon: <IoMdCheckmarkCircleOutline />,
		desc1: 'Placement in the most visited pages',
		desc2: 'Lots of description and image fields',
		desc3: 'High level of CTR',
		desc4: '',
	    },
            {
                title: 'Banner',
                icon: <IoMdCheckmarkCircleOutline />,
                desc1: 'High-performance promoting system',
                desc2: 'High level of CTR',
                desc3: 'Width screen size protection based on the banner size',
                desc4: '',
            },
            {
                title: 'Sponsor Link',
                icon: <IoMdCheckmarkCircleOutline />,
                desc1: 'High-performance promoting system',
                desc2: 'High level of CTR',
                desc3: '256 characters description',
                desc4: '',
            },
        ]
    }
];

export const langAdsToolsIntro = [
    {
        lang: 'es',
        info: [
            {
                mainTitle: 'Propiedades de los anuncios',
                mainImg: require('../images/tools-ads.png').default,
                mainAlt: 'herramientas de anuncios Introducción',
                tools: [
                    {
                        title: 'Pagada para hacer clic(PTC)',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'observación forzada',
                        desc2: 'Protección del tamaño de la pantalla de ancho',
                        desc3: 'Archivo adjunto de redes sociales',
                        desc4: 'descripción de 256 caracteres',
                    },
                    {
                        title: 'Surf',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Mostrar en la URL original de los anuncios',
                        desc2: 'Protección del tamaño de la pantalla de ancho',
                        desc3: 'Archivo adjunto de redes sociales',
                        desc4: 'descripción de 256 caracteres',
                    },
                    {
                        title: 'video',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'solo enlaces de Youtube',
                        desc2: 'observación forzada',
                        desc3: 'Protección del tamaño de la pantalla de ancho',
                        desc4: '',
                    },
                    {
                        title: 'auto',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'el mas barato',
                        desc2: 'Protección del tamaño de la pantalla de ancho',
                        desc3: '',
                        desc4: '',
                    },
		    {
			title: 'historia de hielo',
			icon: <IoMdCheckmarkCircleOutline />,
			desc1: 'Posicionamiento en las páginas más visitadas',
			desc2: 'Muchos campos de descripción e imagen.',
			desc3: 'Alto nivel de CTR',
			desc4: '',
		    },
                    {
                        title: 'bandera',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Sistema de promoción de alto rendimiento',
                        desc2: 'Alto nivel de CTR',
                        desc3: 'Protección del tamaño de la pantalla de ancho basada en el tamaño del banner',
                        desc4: '',
                    },
                    {
                        title: 'enlace de patrocinador',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Sistema de promoción de alto rendimiento',
                        desc2: 'Alto nivel de CTR',
                        desc3: 'descripción de 256 caracteres',
                        desc4: '',
                    },
                ]
            }
        ]
    },
    {
        lang: 'ru',
        info: [
            {
                mainTitle: 'Свойства объявлений',
                mainImg: require('../images/tools-ads.png').default,
                mainAlt: 'объявлений инструменты Введение',
                tools: [
                    {
                        title: 'Платно за клик(PTC)',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Принудительный просмотр',
                        desc2: 'защита размера экрана',
                        desc3: 'вложение для социальных сетей',
                        desc4: '256 символов описания',
                    },
                    {
                        title: 'серфинг',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Отображать в исходном URL объявлений',
                        desc2: 'защита размера экрана',
                        desc3: 'вложение для социальных сетей',
                        desc4: '256 символов описания',
                    },
                    {
                        title: 'видео',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Только ссылки на ютуб',
                        desc2: 'Принудительный просмотр',
                        desc3: 'защита размера экрана',
                        desc4: '',
                    },
                    {
			title: 'авто',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Самый дешевый',
                        desc2: 'защита размера экрана',
                        desc3: '',
                        desc4: '',
                    },
		    {
			title: 'ледяная история',
			icon: <IoMdCheckmarkCircleOutline />,
			desc1: 'Размещение на самых посещаемых страницах',
			desc2: 'Множество полей описания и изображения.',
			desc3: 'Высокий уровень CTR',
			desc4: '',
		    },
                    {
                        title: 'баннер',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Высокоэффективная система продвижения',
                        desc2: 'Высокий уровень CTR',
                        desc3: 'Защита размера экрана в зависимости от размера баннера',
                        desc4: '',
                    },
                    {
                        title: 'спонсорская ссылка',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Высокоэффективная система продвижения',
                        desc2: 'Высокий уровень CTR',
                        desc3: '256 символов описания',
                        desc4: '',
                    },
                ]
            }
        ]
    },
    {
        lang: 'pt',
        info: [
            {
                mainTitle: 'Propriedades de anúncios',
                mainImg: require('../images/tools-ads.png').default,
                mainAlt: 'ferramentas de anúncios Introdução',
                tools: [
                    {
                        title: 'Pago para clicar(PTC)',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Observação forçada',
                        desc2: 'Proteção de tamanho de tela de largura',
                        desc3: 'Anexo de mídia social',
                        desc4: 'descrição de 256 caracteres',
                    },
                    {
                        title: 'Surf',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Exibir no URL original dos anúncios',
                        desc2: 'Proteção de tamanho de tela de largura',
                        desc3: 'Anexo de mídia social',
                        desc4: 'descrição de 256 caracteres',
                    },
                    {
                        title: 'vídeo',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Somente links do Youtube',
                        desc2: 'Observação forçada',
                        desc3: 'Proteção de tamanho de tela de largura',
                        desc4: '',
                    },
                    {
                        title: 'auto',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'O mais barato',
                        desc2: 'Proteção de tamanho de tela de largura',
                        desc3: '',
                        desc4: '',
                    },
		    {
			title: 'história de gelo',
			icon: <IoMdCheckmarkCircleOutline />,
			desc1: 'Posicionamento nas páginas mais visitadas',
			desc2: 'Muitos campos de descrição e imagem.',
			desc3: 'Alto nível de CTR',
			desc4: '',
		    },
                    {
                        title: 'bandeira',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Sistema de promoção de alto desempenho',
                        desc2: 'Alto nível de CTR',
                        desc3: 'Proteção de tamanho de tela de largura com base no tamanho do banner',
                        desc4: '',
                    },
                    {
                        title: 'link do patrocinador',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Sistema de promoção de alto desempenho',
                        desc2: 'Alto nível de CTR',
                        desc3: 'descrição de 256 caracteres',
                        desc4: '',
                    },
                ]
            }
        ]
    },
    {
        lang: 'fr',
        info: [
            {
                mainTitle: 'Propriétés des annonces',
                mainImg: require('../images/tools-ads.png').default,
                mainAlt: 'outils publicitaires Introduction',
                tools: [
                    {
                        title: 'Payé pour cliquer(PTC)',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Regarder forcé',
                        desc2: "Protection de la taille de l'écran",
                        desc3: 'Attachement aux réseaux sociaux',
                        desc4: 'Description en 256 caractères',
                    },
                    {
                        title: 'surfant',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: "Afficher dans l'URL d'origine des annonces",
                        desc2: "Protection de la taille de l'écran",
                        desc3: 'Attachement aux réseaux sociaux',
                        desc4: 'Description en 256 caractères',
                    },
                    {
                        title: 'vidéo',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Seuls les liens Youtube',
                        desc2: 'Regarder forcé',
                        desc3: "Protection de la taille de l'écran",
                        desc4: '',
                    },
                    {
                        title: 'auto',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Le moins cher',
                        desc2: "Protection de la taille de l'écran",
                        desc3: '',
                        desc4: '',
                    },
		    {
			title: 'histoire de glace',
			icon: <IoMdCheckmarkCircleOutline />,
			desc1: 'Placement dans les pages les plus visitées',
			desc2: "De nombreux champs de description et d'image.",
			desc3: 'Haut niveau de CTR',
			desc4: '',
		    },
                    {
                        title: 'bannière',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Système de promotion haute performance',
                        desc2: 'Haut niveau de CTR',
                        desc3: "Protection de la taille de l'écran en fonction de la taille de la bannière",
                        desc4: '',
                    },
                    {
                        title: 'lien parrain',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Système de promotion haute performance',
                        desc2: 'Haut niveau de CTR',
                        desc3: 'Description en 256 caractères',
                        desc4: '',
                    },
                ]
            }
        ]
    },
    {
        lang: 'de',
        info: [
            {
                mainTitle: 'Anzeigeneigenschaften',
                mainImg: require('../images/tools-ads.png').default,
                mainAlt: 'Anzeigen-Tools Einführung',
                tools: [
                    {
                        title: 'Bezahlt, um zu klicken(PTC)',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Erzwungenes Zuschauen',
                        desc2: 'Breite Bildschirmgröße Schutz',
                        desc3: 'Social-Media-Anhang',
                        desc4: '256 Zeichen Beschreibung',
                    },
                    {
                        title: 'Surfen',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Anzeige in Original-URL der Anzeigen',
                        desc2: 'Breite Bildschirmgröße Schutz',
                        desc3: 'Social-Media-Anhang',
                        desc4: '256 Zeichen Beschreibung',
                    },
                    {
                        title: 'Video',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Nur YouTube-Links',
                        desc2: 'Erzwungenes Zuschauen',
                        desc3: 'Breite Bildschirmgröße Schutz',
                        desc4: '',
                    },
                    {
                        title: 'Auto',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Das billigste',
                        desc2: 'Breite Bildschirmgröße Schutz',
                        desc3: '',
                        desc4: '',
                    },
		    {
			title: 'Eisgeschichte',
			icon: <IoMdCheckmarkCircleOutline />,
			desc1: 'Platzierung auf den meistbesuchten Seiten',
			desc2: 'Viele Beschreibungs- und Bildfelder.',
			desc3: 'Hohe CTR',
			desc4: '',
		    },
                    {
                        title: 'Banner',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Leistungsförderndes förderndes System',
                        desc2: 'Hohe CTR',
                        desc3: 'Breite Bildschirmgrößenschutz basierend auf der Bannergröße',
                        desc4: '',
                    },
                    {
                        title: 'Sponsor-Link',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Leistungsförderndes förderndes System',
                        desc2: 'Hohe CTR',
                        desc3: '256 Zeichen Beschreibung',
                        desc4: '',
                    },
                ]
            }
        ]
    },
    {
        lang: 'it',
        info: [
            {
                mainTitle: 'Proprietà degli annunci',
                mainImg: require('../images/tools-ads.png').default,
                mainAlt: 'strumenti pubblicitari Introduzione',
                tools: [
                    {
                        title: 'Pagato per cliccare(PTC)',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Osservazione forzata',
                        desc2: 'Protezione delle dimensioni dello schermo',
                        desc3: 'Attaccamento ai social media',
                        desc4: 'Descrizione di 256 caratteri',
                    },
                    {
                        title: 'fare surf',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: "Visualizza nell'URL originale degli annunci",
                        desc2: 'Protezione delle dimensioni dello schermo',
                        desc3: 'Attaccamento ai social media',
                        desc4: 'Descrizione di 256 caratteri',
                    },
                    {
                        title: 'video',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Solo link Youtube',
                        desc2: 'Osservazione forzata',
                        desc3: 'Protezione delle dimensioni dello schermo',
                        desc4: '',
                    },
                    {
                        title: 'auto',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Il più economico',
                        desc2: 'Protezione delle dimensioni dello schermo',
                        desc3: '',
                        desc4: '',
                    },
		    {
			title: 'storia di ghiaccio',
			icon: <IoMdCheckmarkCircleOutline />,
			desc1: 'Posizionamento nelle pagine più visitate',
			desc2: 'Numerosi campi descrizione e immagine.',
			desc3: 'Alto livello di CTR',
			desc4: '',
		    },
                    {
                        title: 'bandiera',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Sistema di promozione ad alte prestazioni',
                        desc2: 'Alto livello di CTR',
                        desc3: 'Protezione delle dimensioni dello schermo in base alle dimensioni del banner',
                        desc4: '',
                    },
                    {
                        title: 'link dello sponsor',
                        icon: <IoMdCheckmarkCircleOutline />,
                        desc1: 'Sistema di promozione ad alte prestazioni',
                        desc2: 'Alto livello di CTR',
                        desc3: 'Descrizione di 256 caratteri',
                        desc4: '',
                    },
                ]
            }
        ]
    }
]
export const adsToolsHead = [
    'details',
    'sponsor link',
    'banner',
    'ice story',
    'auto',
    'video',
    'surfing',
    'ptc'
];

export const langAdsToolsHead = [
    {
        lang: 'es',
        info: [
            'detalles',
            'enlace de patrocinador',
            'bandera',
            'historia de hielo',
	    'auto',
            'video',
            'surf',
            'ptc'
        ]
    },
    {
        lang: 'ru',
        info: [
            'подробности',
            'спонсорская ссылка',
            'баннер',
            'ледяная история',
	    'авто',
            'видео',
            'серфинг',
            'ptc'
        ]
    },
    {
        lang: 'pt',
        info: [
            'detalhes',
            'link do patrocinador',
            'bandeira',
            'história de gelo',
	    'auto',
            'vídeo',
            'surf',
            'ptc'
        ]
    },
    {
        lang: 'fr',
        info: [
            'détails',
            'lien parrain',
            'bannière',
            'histoire de glace',
	    'auto',
            'vidéo',
            'surfant',
            'ptc'
        ]
    },
    {
        lang: 'de',
        info: [
            'Einzelheiten',
            'Sponsor-Link',
            'Banner',
            'Eisgeschichte',
	    'Auto',
            'Video',
            'Surfen',
            'ptc'
        ]
    },
    {
        lang: 'it',
        info: [
            'dettagli',
            'link dello sponsor',
            'bandiera',
            'storia di ghiaccio',
	    'auto',
            'video',
            'fare surf',
            'ptc'
        ]
    }
];

export const adsToolsRow = [
    {
        'details': '1000 visits',
        'sponsor link': '-',
        'banner': '-',
        'ice story': '-',
	'auto': '$0.40',
        'video': '$0.60',
        'surf': '$0.80',
        'ptc': '$1'
    },
    {
        'details': '10000 impression',
        'sponsor link': '$0.50',
        'banner': '$1',
        'ice story': '$3',
	'auto': '-',
        'video': '-',
        'surf': '-',
        'ptc': '-'
    },
    {
        'details': 'referral filter',
        'sponsor link': 'X',
        'banner': 'X',
        'ice story': 'X',
	'auto': 'X',
        'video': <GiCheckMark />,
        'surf': <GiCheckMark />,
        'ptc': <GiCheckMark />
    },
    {
        'details': 'target language',
        'sponsor link': <GiCheckMark />,
        'banner': <GiCheckMark />,
        'ice story': <GiCheckMark />,
	'auto': 'X',
        'video': <GiCheckMark />,
        'surf': <GiCheckMark />,
        'ptc': <GiCheckMark />
    },
    {
        'details': 'target countries',
        'sponsor link': <GiCheckMark />,
        'banner': <GiCheckMark />,
        'ice story': <GiCheckMark />,
	'auto': 'X',
        'video': <GiCheckMark />,
        'surf': <GiCheckMark />,
        'ptc': <GiCheckMark />
    },
    {
        'details': 'VPN protect',
        'sponsor link': <GiCheckMark />,
        'banner': <GiCheckMark />,
        'ice story': <GiCheckMark />,
	'auto': <GiCheckMark />,
        'video': <GiCheckMark />,
        'surf': <GiCheckMark />,
        'ptc': <GiCheckMark />
    },
    {
        'details': 'device filter',
        'sponsor link': 'X',
        'banner': <GiCheckMark />,
        'ice story': 'X',
	'auto': 'X',
        'video': <GiCheckMark />,
        'surf': <GiCheckMark />,
        'ptc': <GiCheckMark />
    },
    {
        'details': 'gender filter',
        'sponsor link': 'X',
        'banner': 'X',
        'ice story': 'X',
	'auto': 'X',
        'video': <GiCheckMark />,
        'surf': <GiCheckMark />,
        'ptc': <GiCheckMark />
    },
];

export const langAdsToolsRow = [
    {
        lang: 'es',
        info: [
            {
                'details': '1000 visitas',
                'sponsor link': '-',
                'banner': '-',
                'ice story': '-',
		'auto': '$0.40',
                'video': '$0.60',
                'surf': '$0.80',
                'ptc': '$1'
            },
            {
                'details': '10000 impresión',
                'sponsor link': '$0.50',
                'banner': '$1',
                'ice story': '$3',
		'auto': '-',
                'video': '-',
                'surf': '-',
                'ptc': '-'
            },
            {
                'details': 'filtro de referencia',
                'sponsor link': 'X',
                'banner': 'X',
                'ice story': 'X',
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'lengua de llegada',
                'sponsor link': <GiCheckMark />,
                'banner': <GiCheckMark />,
                'ice story': <GiCheckMark />,
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'países de destino',
                'sponsor link': <GiCheckMark />,
                'banner': <GiCheckMark />,
                'ice story': <GiCheckMark />,
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'detalles': 'VPN proteger',
                'sponsor link': <GiCheckMark />,
                'banner': <GiCheckMark />,
                'ice story': <GiCheckMark />,
		'auto': <GiCheckMark />,
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'filtro de dispositivo',
                'sponsor link': 'X',
                'banner': <GiCheckMark />,
                'ice story': 'X',
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'filtro de género',
                'sponsor link': 'X',
                'banner': 'X',
                'ice story': 'X',
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
        ]
    },
    {
        lang: 'ru',
        info: [
	    {
		'details': '1000 посещений',
		'sponsor link': '-',
		'banner': '-',
		'ice story': '-',
		'auto': '$0.40',
		'video': '$0.60',
		'surf': '$0.80',
		'ptc': '$1'
	    },
	    {
		'details': '10000 показов',
		'sponsor link': '$0.50',
		'banner': '$1',
		'ice story': '$3',
		'auto': '-',
		'video': '-',
		'surf': '-',
		'ptc': '-'
	    },
	    {
		'details': 'реферальный фильтр',
		'sponsor link': 'X',
		'banner': 'X',
		'ice story': 'X',
		'auto': 'X',
		'video': <GiCheckMark />,
		'surf': <GiCheckMark />,
		'ptc': <GiCheckMark />
	    },
	    {
		'details': 'язык перевода',
		'sponsor link': <GiCheckMark />,
		'banner': <GiCheckMark />,
		'ice story': <GiCheckMark />,
		'auto': 'X',
		'video': <GiCheckMark />,
		'surf': <GiCheckMark />,
		'ptc': <GiCheckMark />
	    },
	    {
		'details': 'целевые страны',
		'sponsor link': <GiCheckMark />,
		'banner': <GiCheckMark />,
		'ice story': <GiCheckMark />,
		'auto': 'X',
		'video': <GiCheckMark />,
		'surf': <GiCheckMark />,
		'ptc': <GiCheckMark />
	    },
	    {
		'details': 'Защита VPN',
		'sponsor link': <GiCheckMark />,
		'banner': <GiCheckMark />,
		'ice story': <GiCheckMark />,
		'auto': <GiCheckMark />,
		'video': <GiCheckMark />,
		'surf': <GiCheckMark />,
		'ptc': <GiCheckMark />
	    },
	    {
		'details': 'фильтр устройств',
		'sponsor link': 'X',
		'banner': <GiCheckMark />,
		'ice story': 'X',
		'auto': 'X',
		'video': <GiCheckMark />,
		'surf': <GiCheckMark />,
		'ptc': <GiCheckMark />
	    },
	    {
		'details': 'гендерный фильтр',
		'sponsor link': 'X',
		'banner': 'X',
		'ice story': 'X',
		'auto': 'X',
		'video': <GiCheckMark />,
		'surf': <GiCheckMark />,
		'ptc': <GiCheckMark />
	    }
        ]
    },
    {
        lang: 'pt',
        info: [
            {
                'details': '1000 visitas',
                'sponsor link': '-',
                'banner': '-',
                'ice story': '-',
		'auto': '$0.40',
                'video': '$0.60',
                'surf': '$0.80',
                'ptc': '$1'
            },
            {
                'details': '10000 impressões',
                'sponsor link': '$0.50',
                'banner': '$1',
                'ice story': '$3',
		'auto': '-',
                'video': '-',
                'surf': '-',
                'ptc': '-'
            },
            {
                'details': 'filtro de referência',
                'sponsor link': 'X',
                'banner': 'X',
                'ice story': 'X',
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'idioma alvo',
                'sponsor link': <GiCheckMark />,
                'banner': <GiCheckMark />,
                'ice story': <GiCheckMark />,
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'países alvo',
                'sponsor link': <GiCheckMark />,
                'banner': <GiCheckMark />,
                'ice story': <GiCheckMark />,
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'VPN proteger',
                'sponsor link': <GiCheckMark />,
                'banner': <GiCheckMark />,
                'ice story': <GiCheckMark />,
		'auto': <GiCheckMark />,
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'filtro de dispositivo',
                'sponsor link': 'X',
                'banner': <GiCheckMark />,
                'ice story': 'X',
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'filtro de gênero',
                'sponsor link': 'X',
                'banner': 'X',
                'ice story': 'X',
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
        ]
    },
    {
        lang: 'fr',
        info: [
            {
                'details': '1000 visites',
                'sponsor link': '-',
                'banner': '-',
                'ice story': '-',
		'auto': '$0.40',
                'vid�o': '$0.60',
                'surf': '$0.80',
                'ptc': '$1'
            },
            {
                'details': '10000 impression',
                'sponsor link': '$0.50',
                'banner': '$1',
                'ice story': '$3',
		'auto': '-',
                'video': '-',
                'surf': '-',
                'ptc': '-'
            },
            {
                'details': 'filtre de référence',
                'sponsor link': 'X',
                'banner': 'X',
                'ice story': 'X',
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'langue cible',
                'sponsor link': <GiCheckMark />,
                'banner': <GiCheckMark />,
                'ice story': <GiCheckMark />,
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'pays cibles',
                'sponsor link': <GiCheckMark />,
                'banner': <GiCheckMark />,
                'ice story': <GiCheckMark />,
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'Protection VPN',
                'sponsor link': <GiCheckMark />,
                'banner': <GiCheckMark />,
                'ice story': <GiCheckMark />,
		'auto': <GiCheckMark />,
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': "filtre d'appareil",
                'sponsor link': 'X',
                'banner': <GiCheckMark />,
                'ice story': 'X',
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'filtre de genre',
                'sponsor link': 'X',
                'banner': 'X',
                'ice story': 'X',
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
        ]
    },
    {
        lang: 'de',
        info: [
            {
                'details': '1000 Besuche',
                'sponsor link': '-',
                'banner': '-',
                'ice story': '-',
		'auto': '$0.40',
                'video': '$0.60',
                'surf': '$0.80',
                'ptc': '$1'
            },
            {
                'details': '10000 Eindruck',
                'sponsor link': '$0.50',
                'banner': '$1',
                'ice story': '$3',
		'auto': '-',
                'video': '-',
                'surf': '-',
                'ptc': '-'
            },
            {
                'details': 'Empfehlungsfilter',
                'sponsor link': 'X',
                'banner': 'X',
                'ice story': 'X',
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'Zielsprache',
                'sponsor link': <GiCheckMark />,
                'banner': <GiCheckMark />,
                'ice story': <GiCheckMark />,
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'Zielländer',
                'sponsor link': <GiCheckMark />,
                'banner': <GiCheckMark />,
                'ice story': <GiCheckMark />,
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'VPN-Schutz',
                'sponsor link': <GiCheckMark />,
                'banner': <GiCheckMark />,
                'ice story': <GiCheckMark />,
		'auto': <GiCheckMark />,
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'Gerätefilter',
                'sponsor link': 'X',
                'banner': <GiCheckMark />,
                'ice story': 'X',
		'auto': 'X',
                'video': <GiCheckMark />,
                'surfen': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'Geschlechtsfilter',
                'sponsor link': 'X',
                'banner': 'X',
                'ice story': 'X',
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
        ]
    },
    {
        lang: 'it',
        info: [
            {
                'details': '1000 visite',
                'sponsor link': '-',
                'banner': '-',
                'ice story': '-',
		'auto': '$0.40',
                'video': '$0.60',
                'surf': '$0.80',
                'ptc': '$1'
            },
            {
                'details': '10000 impressioni',
                'sponsor link': '$0.50',
                'banner': '$1',
                'ice story': '$3',
		'auto': '-',
                'video': '-',
                'surf': '-',
                'ptc': '-'
            },
            {
                'details': 'filtro di riferimento',
                'sponsor link': 'X',
                'banner': 'X',
                'ice story': 'X',
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'lingua di destinazione',
                'sponsor link': <GiCheckMark />,
                'banner': <GiCheckMark />,
                'ice story': <GiCheckMark />,
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'paesi bersaglio',
                'sponsor link': <GiCheckMark />,
                'banner': <GiCheckMark />,
                'ice story': <GiCheckMark />,
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'Protezione VPN',
                'sponsor link': <GiCheckMark />,
                'banner': <GiCheckMark />,
                'ice story': <GiCheckMark />,
		'auto': <GiCheckMark />,
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'filtro del dispositivo',
                'sponsor link': 'X',
                'banner': <GiCheckMark />,
                'ice story': 'X',
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
            {
                'details': 'filtro di genere',
                'sponsor link': 'X',
                'banner': 'X',
                'ice story': 'X',
		'auto': 'X',
                'video': <GiCheckMark />,
                'surf': <GiCheckMark />,
                'ptc': <GiCheckMark />
            },
        ]
    }
];
