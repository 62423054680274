import styled from 'styled-components';
import device from '../../constants/breakPoints';

export const CircleCardWrapper = styled.div`
    width: 100%;
    max-width: 94vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: rgba(29, 107, 187, 0.2);
    border: 1px solid rgba(29, 107, 187, 0.3);
    box-shadow: 0 0 20px rgba(29, 107, 187, 0.7);
    border-radius: 10px;
    padding: 15px 0;
    @media screen and ${device.lg} {
	grid-template-columns: 1fr;
	grid-row-gap: 20px;
    }
`
export const CircleCard = styled.div`
    width: 450px;
    height: 380px;
    background: rgba(29, 107, 187, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    border: 1px solid rgba(9, 107, 187, 0.7);
    box-shadow: 0 0 10px rgba(29, 107, 187, 1);
    margin: auto;
    transition: all 0.2s ease-in-out;
    &:hover {
        transform: scale(1.02);
        background: rgba(29, 107, 187, 0.6);
        transition: all 0.2s ease-in-out;
    }
    @media screen and ${device.sm} {
        width: 314px;
    }
    @media screen and ${device.tn} {
	width: 230px;
    }
`
