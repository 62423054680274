import styled from 'styled-components';
import { Header, Cell } from '../UpgradeContainer/UpgradeContainerElements';
import device from '../../constants/breakPoints';

export const Container = styled.div`
    background: #f9f9f9;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
`
export const Wrapper = styled.div`
    width: 100%;
    max-width: 94vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(29, 107, 187, 0.5);
    border: 1px solid rgba(29, 107, 187, 0.7);
    box-shadow: 0 0 20px rgba(29, 107, 187, 1);
    border-radius: 10px;
    padding: 10px;
    margin-top: 40px;
`
export const HeaderAds = styled(Header)`
    font-size: clamp(0.6rem, 2vw, 0.85rem);
    @media screen and ${device.sm} {
        padding: 10px 0;
    }
    &:nth-child(2) {
        background: rgba(29, 107, 187, 0.3);
    }
    &:nth-child(3) {
        background: rgba(29, 107, 187, 0.4);
    }
    &:nth-child(4) {
        background: rgba(29, 107, 187, 0.5);
    }
    &:nth-child(5) {
        background: rgba(29, 107, 187, 0.6);
    }
    &:nth-child(6) {
        background: rgba(29, 107, 187, 0.7);
    }
    &:nth-child(7) {
        background: rgba(29, 107, 187, 0.8);
    }
    &:nth-child(8) {
	background: rgba(29, 107, 187, 0.9);
    }
`
export const CellAds = styled(Cell)`
    font-size: clamp(0.6rem, 2vw, 0.85rem);
    @media screen and ${device.sm} {
        padding: 10px 0;
    }
    &:nth-child(2) {
        background: rgba(29, 107, 187, 0.3);
    }
    &:nth-child(3) {
        background: rgba(29, 107, 187, 0.4);
    }
    &:nth-child(4) {
        background: rgba(29, 107, 187, 0.5);
    }
    &:nth-child(5) {
        background: rgba(29, 107, 187, 0.6);
    }
    &:nth-child(6) {
        background: rgba(29, 107, 187, 0.7);
    }
    &:nth-child(7) {
        background: rgba(29, 107, 187, 0.8);
    }
    &:nth-child(8) {
	background: rgba(29, 107, 187, 0.9);
    }
`
export const PIContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
`
export const PIWrap = styled.div`
    width: 95%;
    max-width: 94vw;
    display: flex;
`
export const PIIntroCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    padding: 0 20px;
    background: rgba(29, 107, 187, 0.6);
    border-radius: 15px 0 0 15px;
    border: 1px solid rgba(29, 107, 187, 0.7);
    transition: all 0.2s ease-in-out;
`
export const IntroImgWrap = styled.div`
    color: #fff;
    font-size: 1rm;
    margin-bottom: 15px;

    img {
        width: 100px;
        height: 100px;
    }
`
export const IntroTitle = styled.div`
    text-align: center;
    background: rgba(29, 107, 187, 0.5);
    padding: 2px 5px;
    border-radius: 5px;

    h4 {
        color: #01DBBB;
        font-size: clamp(0.85rem, 2vw, 1.2rem);
        font-weight: 700;
        letter-spacing: 1.3px;
        word-spacing: 4px;
        text-transform: uppercase;
        text-shadow: 0 1px 2px #000
    }
`
export const PIItems = styled.div`
    display: flex;
    padding-top: 5px;
    overflow-x: auto;
    background: rgba(29, 107, 187, 0.35);

    &::-webkit-scrollbar {
        width: 10px;
        height: 14px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 4px rgba(29, 107, 187, 0.5); 
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(29, 107, 187, 0.6); 
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(29, 107, 187, 0.8); 
    }
    `
export const PIItemsWrap = styled.div`
    margin: 15px 15px 10px 15px;
`
export const PIItemsCard = styled.div`
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.6)' : 'rgba(39, 117, 85, 0.6)')};
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: 275px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
    box-shadow: 0 0 10px ${({ primary }) => (primary ? 'rgba(29, 107, 187, 1)' : 'rgba(39, 117, 85, 1)')};
    transition: all 0.2s ease-in-out;

    @media screen and ${device.md} {
        width: 225px;
    }
    @media screen and ${device.sm} {
        width: 190px;
    }

    &:hover {
        transform: scale(1.02);
        background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.7)' : 'rgba(39, 117, 85, 0.7)')};
        transition: all 0.2s ease-in-out;
    }
`
export const TitleWrap = styled.div`
    text-align: center;
    background: ${({ primary }) => (primary ? 'rgba(29, 107, 187, 0.8)' : 'rgba(39, 117, 85, 0.8)')};

    h4 {
        color: #01DBBB;
        font-size: clamp(0.85rem, 2vw, 1.05rem);
        font-weight: 400;
        letter-spacing: 1.3px;
        word-spacing: 4px;
        text-transform: uppercase;
    }
`
export const ItemsContentWrap = styled.div`
    display: flex;
    align-items: flex-start;
    margin-top: 10px;

    div {
        color: #01DBBB;
        font-size: clamp(1rem, 2vw, 1.2rem);
        margin: 1px 5px 0 0;
    }
    p {
        color: #fff;
        font-size: clamp(0.8rem, 2vw, 1rem);
        line-height: 1.3;
        text-shadow: 0 1px 3px #000;
    }
`
export const DetailItem = styled.p`
    color:  ${({ green }) => (green ? 'rgb(39, 117, 85)' : 'rgb(29, 107, 187)')};
    font-size: clamp(0.8rem, 2.5vw, 1.04rem);
    margin-top: 5px;
`
