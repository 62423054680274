export const homeNavbar = [
    {
        title: 'make money',
        link: 'make money'
    },
    {
        title: 'advertise',
        link: 'advertise'
    },
    {
        title: 'statistics',
        link: 'statistics'
    },
    {
        title: 'features',
        link: 'features'
    }
];
export const langHomeNavbar = [
    {
        lang: 'es',
        info: [
            {
                title: 'ganar dinero',
                link: 'make money'
            },
            {
                title: 'anunciar',
                link: 'advertise'
            },
            {
                title: 'Estadísticas',
                link: 'statistics'
            },
            {
                title: 'características',
                link: 'features'
            }
        ]
    },
    {
        lang: 'ru',
        info: [
            {
                title: 'Зарабатывайте деньги',
                link: 'make money'
            },
            {
                title: 'рекламировать',
                link: 'advertise'
            },
            {
                title: 'статистика',
                link: 'statistics'
            },
            {
                title: 'функции',
                link: 'features'
            }
        ]
    },
    {
        lang: 'pt',
        info: [
            {
                title: 'ganhar dinheiro',
                link: 'make money'
            },
            {
                title: 'anunciar',
                link: 'advertise'
            },
            {
                title: 'Estatisticas',
                link: 'statistics'
            },
            {
                title: 'características',
                link: 'features'
            }
        ]
    },
    {
        lang: 'fr',
        info: [
            {
                title: "gagner de l'argent",
                link: 'make money'
            },
            {
                title: 'afficher',
                link: 'advertise'
            },
            {
                title: 'statistiques',
                link: 'statistics'
            },
            {
                title: 'caractéristiques',
                link: 'features'
            }
        ]
    },
    {
        lang: 'de',
        info: [
            {
                title: 'Geld verdienen',
                link: 'make money'
            },
            {
                title: 'werben',
                link: 'advertise'
            },
            {
                title: 'Statistiken',
                link: 'statistics'
            },
            {
                title: 'Merkmale',
                link: 'features'
            }
        ]
    },
    {
        lang: 'it',
        info: [
            {
                title: 'guadagnare soldi',
                link: 'make money'
            },
            {
                title: 'pubblicizzare',
                link: 'advertise'
            },
            {
                title: 'statistiche',
                link: 'statistics'
            },
            {
                title: 'caratteristiche',
                link: 'features'
            }
        ]
    }
];
export const adsNavbar = [
    {
        title: 'special filters',
        link: 'special filters'
    },
    {
        title: 'advertising tools',
        link: 'ads tools'
    },
    {
        title: 'calculator',
        link: 'ads calculator'
    },
];
export const langAdsNavbar = [
    {
        lang: 'es',
        info: [
            {
                title: 'filtros especiales',
                link: 'special filters'
            },
            {
                title: 'herramientas publicitarias',
                link: 'ads tools'
            },
            {
                title: 'calculadora',
                link: 'ads calculator'
            },
        ]
    },
    {
        lang: 'ru',
        info: [
            {
                title: 'специальные фильтры',
                link: 'special filters'
            },
            {
                title: 'рекламные инструменты',
                link: 'ads tools'
            },
            {
                title: 'калькулятор',
                link: 'ads calculator'
            },
        ]
    },
    {
        lang: 'pt',
        info: [
            {
                title: 'filtres spéciaux',
                link: 'special filters'
            },
            {
                title: 'ferramentas de publicidade',
                link: 'ads tools'
            },
            {
                title: 'calculadora',
                link: 'ads calculator'
            },
        ]
    },
    {
        lang: 'fr',
        info: [
            {
                title: 'filtres spéciaux',
                link: 'special filters'
            },
            {
                title: 'outils publicitaires',
                link: 'ads tools'
            },
            {
                title: 'calculatrice',
                link: 'ads calculator'
            },
        ]
    },
    {
        lang: 'de',
        info: [
            {
                title: 'spezielle Filter',
                link: 'special filters'
            },
            {
                title: 'Werbemittel',
                link: 'ads tools'
            },
            {
                title: 'Taschenrechner',
                link: 'ads calculator'
            },
        ]
    },
    {
        lang: 'it',
        info: [
            {
                title: 'filtri speciali',
                link: 'special filters'
            },
            {
                title: 'strumenti pubblicitari',
                link: 'ads tools'
            },
            {
                title: 'calcolatrice',
                link: 'ads calculator'
            },
        ]
    },
];
export const makeMoneyNavbar = [
    {
        title: 'special features',
        link: 'special features'
    },
    {
        title: 'how to earn',
        link: 'how to earn'
    }
];
export const langMakeMoneyNavbar = [
    {
        lang: 'es',
        info: [
            {
                title: 'características especiales',
                link: 'special features'
            },
            {
                title: 'como obtener',
                link: 'how to earn'
            }
        ]
    },
    {
        lang: 'ru',
        info: [
            {
                title: 'особые возможности',
                link: 'special features'
            },
            {
                title: 'как заработать',
                link: 'how to earn'
            }
        ]
    },
    {
        lang: 'pt',
        info: [
            {
                title: 'características especiais',
                link: 'special features'
            },
            {
                title: 'como ganhar',
                link: 'how to earn'
            }
        ]
    },
    {
        lang: 'fr',
        info: [
            {
                title: 'caractéristiques spéciales',
                link: 'special features'
            },
            {
                title: 'comment gagner',
                link: 'how to earn'
            }
        ]
    },
    {
        lang: 'de',
        info: [
            {
                title: 'besondere Merkmale',
                link: 'special features'
            },
            {
                title: 'wie verdient man',
                link: 'how to earn'
            }
        ]
    },
    {
        lang: 'it',
        info: [
            {
                title: 'caratteristiche speciali',
                link: 'special features'
            },
            {
                title: 'come guadagnare',
                link: 'how to earn'
            }
        ]
    },
];
export const statisticsNavbar = [
    {
        title: 'general data',
        link: 'general data'
    },
    {
        title: 'income data',
        link: 'earning data'
    },
    {
        title: 'payment data',
	link: 'payment data'
    }
];
export const langStatisticsNavbar = [
    {
        lang: 'es',
        info: [
            {
                title: 'Datos públicos',
                link: 'general data'
            },
            {
                title: 'Datos de ingresos',
                link: 'earning data'
            },
            {
                title: 'Datos de pago',
		link: 'payment data'
            }
        ]
    },
    {
        lang: 'ru',
        info: [
            {
                title: 'Общедоступные данные',
                link: 'general data'
            },
            {
                title: 'Данные о доходах',
                link: 'earning data'
            },
            {
                title: 'платежная информация',
		link: 'payment data'
            }
        ]
    },
    {
        lang: 'pt',
        info: [
            {
                title: 'dados públicos',
                link: 'general data'
            },
            {
                title: 'dados de renda',
                link: 'earning data'
            },
            {
                title: 'dados de pagamento',
		link: 'payment data'
            }
        ]
    },
    {
        lang: 'fr',
        info: [
            {
                title: 'données publiques',
                link: 'general data'
            },
            {
                title: 'données sur le revenu',
                link: 'earning data'
            },
            {
                title: 'données de paiement',
		link: 'payment data'
            }
        ]
    },
    {
        lang: 'de',
        info: [
            {
                title: 'Öffentliche Daten',
                link: 'general data'
            },
            {
                title: 'Einkommensdaten',
                link: 'earning data'
            },
            {
                title: 'Zahlungsdaten',
		link: 'payment data'
            }
        ]
    },
    {
        lang: 'it',
        info: [
            {
                title: 'Dati pubblici',
                link: 'general data'
            },
            {
                title: 'Dati sul reddito',
                link: 'earning data'
            },
            {
                title: 'dati di pagamento',
		link: 'payment data'
            }
        ]
    }
];
