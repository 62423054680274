import React, { useState } from 'react';
import axiosInstance from '../../axios';
import { useHistory } from 'react-router-dom';
import { SidebarContainer } from '../Sidebar/SidebarElements';
import { CloseIconWrap, CloseIcon } from '../ManageRef/MoreDetailElements';
import { ModalWrapper } from '../AdsControl/MoreInfoModalElements';
import { ContentWrap, TimePriceWrap, TimeWrap, PriceWrap, TimerDeposit, InstructionWrap, ValueAddressWrap, ItemContainer, ItemWrap } from './ModalElements';
import Countdown, { zeroPad } from 'react-countdown';
import { FormButton } from '../ButtonElements';
import { MsgWrap, MsgText } from '../MessageElements';

const InterModal = ({ crypto, cryptoName, value, timer, setTimer, isSubmitting, setIsSubmitting, price }) => {
    let history = useHistory();
    const [apiError, setApiError] = useState('');
    const [complete, setComplete] = useState('');
    const renderer = ({ minutes, seconds }) => {
	return <TimeWrap red={timer < Date.now() + 60 * 1000 ? 1 : 0}>
	    <TimerDeposit red={timer < Date.now() + 60 * 1000 ? 1 : 0}>{zeroPad(minutes)}:{zeroPad(seconds)}</TimerDeposit>
	</TimeWrap>;
    };
    const fetchManualDeposit = async () => {
	try {
	    await axiosInstance.post('financial/cash-in/internal/', {
		'processor': crypto,
		'value_coin': value
	    });
	    setComplete(true);
	    setTimeout(() => history.push('/history-panel'), 2000);
	} catch (error) {
	    error.response && error.response.data.detail ? setApiError(error.response.data.detail) : setApiError(error.message);
	};
    };
    const handleManualDeposit = () => {
	fetchManualDeposit();
    };
    return (
	<SidebarContainer isOpen={isSubmitting ? 1 : 0}>
	    <ModalWrapper>
	        <CloseIconWrap onClick={() => { setIsSubmitting(false); setApiError(''); }}>
	            <CloseIcon />
	        </CloseIconWrap>
	        {price !== undefined &&
		    <ContentWrap style={{ height: '90%' }}>
			<div style={{ width: '100%' }}>
			    <TimePriceWrap>
			        <Countdown
			            date={timer}
			            renderer={renderer}
			            onComplete={() => {
					setTimeout(() => history.go(0), 2000);
					setTimer(0);
				    }}
			        />
			        <PriceWrap>
			            <h2>1 {crypto} = {price} usd</h2>
			        </PriceWrap>
			    </TimePriceWrap>
			    <InstructionWrap>
			        <p>
			            You want to exchange the following number of {cryptoName}s for adscoins. The conversion rate is 1 {cryptoName} = {cryptoName === 'icecoin' ? 10 : 1} adscoin. Click on the button below to complete this transaction.
			        </p>
			    </InstructionWrap>
			    <ValueAddressWrap>
			        <ItemContainer>
			            <ItemWrap>
			                <h3>{cryptoName === 'icecoin' ? value / 10 : value}</h3>
			            </ItemWrap>
			        </ItemContainer>
			    </ValueAddressWrap>
			</div>
			{apiError.length === 0 && !complete && timer > 0 &&
			    <FormButton
				type='button'
				style={{ width: '80%', maxWidth: '500px' }}
				big={0}
				primary={1}
				onClick={handleManualDeposit}
			    >
				Exchange
			    </FormButton>
			}
			{apiError &&
			    <MsgWrap style={{ width: '80%' }} primary={0}>
				<MsgText primary={0}>
				    {apiError}
				</MsgText>
			    </MsgWrap>
			}
			{timer === 0 &&
			    <MsgWrap style={{ width: '80%' }} primary={0}>
				<MsgText primary={0}>
				    Your time is expired. Please try again.
				</MsgText>
			    </MsgWrap>
			}
			{complete && !apiError &&
			    <MsgWrap style={{ width: '80%' }} primary={1}>
				<MsgText primary={1}>
				    Your deposit was successfully submitted.
				</MsgText>
			    </MsgWrap>
			}
		    </ContentWrap>
		}
	    </ModalWrapper>
	</SidebarContainer >
    )
}
export default InterModal;
